import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";

@Injectable({
  providedIn: 'root'
})
export class Fiche_a_classerService {

  Doctype_fiche_a_classer ='Fiche_a_classer_v15'


  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService) {}

 // Fiche a classer

  async getAllMyFiche(filter=null) {
    let my_fiches = await this.frapper.GetDoctypes(this.Doctype_fiche_a_classer,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_fiches
  }

  async getOneFiche(ficheName) {
    let my_fiche = await this.frapper.GetDoctype(this.Doctype_fiche_a_classer,ficheName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_fiche
  }

  async nouvelleFiche(body) {
    let new_fiche = await this.frapper.CreateDoctype(this.Doctype_fiche_a_classer,body).toPromise()
    return new_fiche
  }

  async updateSatate(docname,new_state){
    let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_fiche_a_classer,docname,body).toPromise()
    return result
  }

  async updateFiche(docname,body){
    let result = this.frapper.UpdateDoctype(this.Doctype_fiche_a_classer,docname,body).toPromise()
    return result
  }

  async assignRoleToUser(body){
    let assignation = await this.assigneService.doAssignation(this.Doctype_fiche_a_classer,body.name,body.user)
    return assignation
  }

 // ** Fiche a classer
}
