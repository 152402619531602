import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertesService } from '../../services/public/alertes.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-visualize-piece',
  templateUrl: './visualize-piece.component.html',
  styleUrls: ['./visualize-piece.component.scss']
})
export class VisualizePieceComponent implements OnInit{

  // urlimage = 'https://petapixel.com/assets/uploads/2022/06/what-is-a-jpeg-featured.jpg';
  // urlpdf = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  urlSafe :any;
  data: any;
  is_image: boolean = false;
  extension: string;
   isImage;
  constructor(private modalService: BsModalService,
    public options: ModalOptions,

    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    public sanitizer : DomSanitizer
  ){
this.data = options.initialState
this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
  options.initialState.url.toString()
);

  
}

getExtension(file: string) {
  if (!file) {
    // Handle the case when file is undefined
    console.error("File is undefined");
    return;
  }

  const parts = file.split('.');
  this.extension = parts[parts.length - 1];
  if (this.extension === 'jpeg' || this.extension === 'jpg' || this.extension === 'png') {
    this.is_image = true;
    console.log('is an image' + this.extension);
  }
}




  async ngOnInit(): Promise<void> {

    await this.getExtension(this.data)
   

     /* this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.data
      );*/

      $(document).ready(function() {
        var iframe = document.getElementById('myFrame');



        $(iframe).css({
          'background-size': 'cover',
          'min-height': '700px'
        });
      });

  }
}
