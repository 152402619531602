<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
              <h5 id="heading">Nouveau Dossier</h5>
              <!-- <p>Fill all form field to go to next step</p> -->
              <form id="msform" #newdossier="ngForm" (ngSubmit)="AddNewCourrier(newdossier)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Détails</strong></li>
                      <li id="step2"><strong>Information rédacteur</strong></li>
                      <li id="step3"><strong>Identification des parties prenantes</strong></li>
                       <li id="step4"><strong>Élements du dossier</strong></li>

                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->
                  <fieldset class="step1">
                      <div class="form-card">
                          <div class="row">
                              <!-- <div class="col-7">
                                  <h2 class="fs-title">Account Information:</h2>
                              </div>
                              <div class="col-5">
                                  <h2 class="steps">Step 1 - 4</h2>
                              </div> -->
                          </div>
                          <div class="row">
                            <div class="col">

                              <label class="fieldlabels">Classification: *</label>
                                  <input *ngIf="data.classification" type="text" class="form-control" name="classification" (change)="onClassificationChange($event)"  [ngModel]="data.classification" ngModel required/>
                                  <select *ngIf="!data.classification" class="custom-select" id="inputGroupSelect01" name="classification" ngModel required>
                                    <option *ngFor="let classification of classifications" value="{{classification.name}}">{{classification.name}}</option>
                                  </select>
                              <label class="fieldlabels">Nature: *</label>
                                  <input  *ngIf="data.nature" type="text" class="form-control" name="nature" [ngModel]="data.nature" ngModel required/>
                                  <select *ngIf="!data.nature" class="custom-select" id="inputGroupSelect01" name="nature" ngModel>
                                    <option *ngFor="let nature of natures" value="{{nature.name}}">{{nature.name}}</option>
                                  </select>
                              <label class="fieldlabels">Référence: </label>
                              <input *ngIf="data.reference" type="text" class="form-control" [ngModel]="data.reference" name="reference" ngModel required/>
                             <select *ngIf="! data.reference" class="custom-select" id="inputGroupSelect01" name="reference" ngModel>
                               <option value="Non">Non</option>
                               <option value="Oui">Oui</option>
                             </select>

                             <label class="fieldlabels">Numéro d'origine: </label>
                                <input class="form-control" type="text" name="numero_dorigine" [ngModel]="data.numero_dorigine" ngModel/>
                            </div>&nbsp;&nbsp;
                            <div class="col d-flex  flex-column justify-content-between " >
                             <label class="fieldlabels">Numéro courrier: *</label>
                                <input class="form-control" type="text" name="numero_courrier" [ngModel]="data.numero_courrier" ngModel/>
                             <label class="fieldlabels">Informations de la référence: </label>
                             <textarea style="height: 220px;" class="form-control"   name="information_de_la_reference" [ngModel]="data.information_de_la_reference" ngModel></textarea>

                            </div>
                          </div>


                      </div> <input type="button" name="next" class="next action-button" value="Suivant" />
                  </fieldset >

                  <fieldset class="step2">
                      <div class="form-card">

                        <div class="row">
                          <div class="col">

                           <label class="fieldlabels">Type d'identification: </label>
                            <input *ngIf="data.type_identification" type="text" class="form-control" [ngModel]="data.type_identification" name="type_identification" ngModel/>
                            <select *ngIf="! data.type_identification" class="custom-select" id="inputGroupSelect01" name="type_identification" ngModel>
                              <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                            </select>


                            <label class="fieldlabels">Prénom: </label>
                              <input class="form-control" type="text" name="prenom" [ngModel]="data.prenom" ngModel required/>
                           <label class="fieldlabels">Pays: </label>
                            <input *ngIf="data.pays" type="text" class="form-control" [ngModel]="data.pays" name="pays" ngModel/>
                            <select *ngIf="! data.pays" class="custom-select" id="inputGroupSelect01" name="pays" ngModel>
                              <option value="SENEGAL">SENEGAL</option>
                              <option value="AUTRES">AUTRES</option>
                            </select>

                           <label class="fieldlabels">Région: </label>
                              <input class="form-control" type="text" name="region" [ngModel]="data.region" ngModel/>
                           <label class="fieldlabels">Adresse: </label>
                              <input class="form-control" type="text" name="adresse" [ngModel]="data.adresse" ngModel/>
                          </div> &nbsp; &nbsp;
                          <div class="col d-flex  flex-column justify-content-between " >
                           <label class="fieldlabels">Numéro d'identification: </label>
                              <input type="text" class="form-control" name="numero_identification" [ngModel]="data.numero_identification" ngModel/>
                           <label class="fieldlabels">Nom: </label>
                              <input class="form-control" type="text" name="nom" [ngModel]="data.nom" ngModel/>
                           <label class="fieldlabels">Numéro d'origine: </label>
                              <input class="form-control" type="text" name="numero_d_origine" [ngModel]="data.numero_d_origine" ngModel/>
                           <label class="fieldlabels">Rédaction: </label>
                           <textarea style="height: 120px;" class="form-control"  name="redaction" [ngModel]="data.redaction" ngModel></textarea>

                          </div>
                        </div>

                        <div class="row">
                          <div class="col">

                            <label class="fieldlabels">Type d'identification (Expéditeur): </label>
                            <input *ngIf="data.type_identf_expediteur" type="text" class="form-control" [ngModel]="data.type_identf_expediteur" name="type_identf_expediteur" ngModel/>
                            <select *ngIf="! data.type_identf_expediteur" class="custom-select" id="inputGroupSelect01" name="type_identf_expediteur" ngModel>
                              <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                            </select>
                          </div>&nbsp;
                          <div class="col">
                            <label class="fieldlabels">Numéro identification (Expéditeur) </label>
                            <input class="form-control" type="text" name="num_identif_expediteur" [ngModel]="data.num_identif_expediteur" ngModel/>
                          </div>
                        </div>

                      </div> <input type="button" name="next" class="next action-button" value="Suivant" />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                  </fieldset>


                  <fieldset class="step3" >
                    <div class="form-card" >
                      
                        <!-- <div class="row"> -->
                         
                          <!-- <div class="filterselect  mt-2 col-xl-5 col-lg-5 col-md-5 m-auto">
                            <label class="select">
                              <select (change)="selectTypePrevenu($event.target.value)">
                                <option>--Choisir le nombre de prevenu--</option>
                                <option [value]="0">Un seul prevenu</option>
                                <option [value]="1">Plusieurs prevenus</option>
                              </select>
                            </label>
                            
                            
                          </div> -->
                        <!-- </div> -->

                        <div class="row">
                         
                          <ng-container *ngIf="is_multiple_prevenu == 0 ;else secondBlock">
                          <div class="col">
                            <label class="fieldlabels">Type d'identification du plaignant: </label>
                            <select class="custom-select" id="inputGroupSelect01" name="type_identification_plaignant" ngModel>
                              <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                            </select>
                            <label class="fieldlabels">Numéro d'identification plaignant: </label>
                                <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>
                            <label class="fieldlabels">Nom complet plaignant: </label>
                            <input type="text" class="form-control" name="nom_complet_du_plaignant" ngModel/>
                            <label class="fieldlabels">Lieu de naissance plaignant: </label>
                            <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" ngModel/>
                            <label class="fieldlabels">Date de naissance plaignant: </label>
                            <input type="date" class="form-control" name="date_de_naissance_du_plaignant" ngModel/>
                            <label class="fieldlabels">Adresse complet du plaignant </label>
                            <input class="form-control" type="text" name="addresse_complete_du_plaignant" ngModel/>
                            <label class="fieldlabels">Nom complet de la mére du plaignant </label>
                            <input class="form-control" type="text" name="nom_complet_de_la_mere_du_plaignant" ngModel/>
                            <label class="fieldlabels">Nom complet du pére du plaignant </label>
                            <input class="form-control" type="text" name="nom_complet_du_pere_du_plaignant" ngModel/>
                          </div> &nbsp; &nbsp;
                          
                            <div class="col d-flex  flex-column justify-content-between " >
                              <div class="d-flex mb-3"  >
                                <label class="fieldlabels mt-4">Activer pour le mode plusieurs prevenus: </label>
                                <div class="toggle-wrapper "  >
                                  <input class="toggle-input" type="checkbox" (change)="selectTypePrevenu($event)"  id="pin-toggle"/>
                                  <label class="toggle-container" for="pin-toggle">
                                    <div class="toggle-circle">
                                      <div class="pin-icon">
                                        <span class="pin-top"></span>
                                        <span class="pin-middle"></span>
                                        <span class="pin-bottom"></span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <label class="fieldlabels">Type d'identification du prévenu: </label>
                              <select class="custom-select" id="inputGroupSelect01" name="type_identification_du_poursuivis" ngModel>
                                <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                              </select>
                              <label class="fieldlabels">Numéro d'identification prévenu: </label>
                                  <input type="text" class="form-control" name="numéro_didentification_du_poursuivis" ngModel/>
                              <label class="fieldlabels">Nom complet prévenu: </label>
                                  <input type="text" class="form-control" name="nom_complet_du_poursuivis" ngModel/>
                              <label class="fieldlabels">Lieu de naissance prévenu: </label>
                              <input type="text" class="form-control" name="lieu_de_naissance_du_prévenu" ngModel/>
                              <label class="fieldlabels">Date de naissance prévenu: </label>
                              <input type="date" class="form-control" name="date_de_naissance_du_prévenu" ngModel/>
                              <label class="fieldlabels">Adresse complète du prévenu </label>
                                  <input class="form-control" type="text" name="addresse_complete_du_poursuivis" ngModel/>
  
                              <label class="fieldlabels">Nom complet de la mére du prévenu </label>
                              <input class="form-control" type="text" name="nom_complet_de_la_mere_du_prévenu" ngModel/>
                              <label class="fieldlabels">Nom complet du pére du prévenu </label>
                              <input class="form-control" type="text" name="nom_complet_du_pere_du_prévenu" ngModel/>
                            </div>
                          </ng-container>
                          <ng-template #secondBlock>
                            <div class="col">
                              <label class="fieldlabels">Type d'identification du plaignant: </label>
                              <select class="custom-select" id="inputGroupSelect01" name="type_identification_plaignant" ngModel>
                                <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                              </select>
                              <label class="fieldlabels">Numéro d'identification plaignant: </label>
                                  <input type="text" class="form-control" name="numéro_didentification_plaignant" ngModel/>
                              <label class="fieldlabels">Nom complet plaignant: </label>
                                  <input type="text" class="form-control" name="nom_complet_du_plaignant" ngModel/>
                              <label class="fieldlabels">Lieu de naissance plaignant: </label>
                              <input type="text" class="form-control" name="lieu_de_naissance_du_plaignant" ngModel/>
                              <label class="fieldlabels">Date de naissance plaignant: </label>
                              <input type="date" class="form-control" name="date_de_naissance_du_plaignant" ngModel/>
                              <label class="fieldlabels">Adresse complet du plaignant </label>
                              <input class="form-control" type="text" name="addresse_complete_du_plaignant" ngModel/>
                              <label class="fieldlabels">Nom complet de la mére du plaignant </label>
                              <input class="form-control" type="text" name="nom_complet_de_la_mere_du_plaignant" ngModel/>
                              <label class="fieldlabels">Nom complet du pére du plaignant </label>
                              <input class="form-control" type="text" name="nom_complet_du_pere_du_plaignant" ngModel/>
                              
                            </div> &nbsp; &nbsp;
                            
                              <div class="col d-flex  flex-column justify-content-start " >
                                <div class="d-flex mb-3"  >
                                  <label class="fieldlabels mt-4">Activer pour le mode plusieurs prevenus: </label>
                                  <div class="toggle-wrapper "  >
                                    <input class="toggle-input" checked type="checkbox" (change)="selectTypePrevenu($event)"  id="pin-toggle"/>
                                    <label class="toggle-container" for="pin-toggle">
                                      <div class="toggle-circle">
                                        <div class="pin-icon">
                                          <span class="pin-top"></span>
                                          <span class="pin-middle"></span>
                                          <span class="pin-bottom"></span>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                <label ><b class="bg-dark text-light">Liste prevenus:</b>&nbsp;&nbsp; <i id="addRowBtn"  class="fa fa-solid fa-plus text-success" style="font-size:22px; cursor:pointer" (click)="openModalAddPrevenu()"></i></label>
                                <div style="font-size: 13px;min-height: 600px; border: dashed 1px grey;">
                                  <table class="table table-striped" style="font-size: 13px;">
                                    <thead>
                                      <tr class="bg-secondary text-light">
                                        <th scope="col">#</th>
                                        <th scope="col">Nom complet</th>
                                        <!-- <th scope="col">Type identification</th>
                                        <th scope="col">Numero identification</th> -->
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let prevenu of prevenusList; let i = index">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ prevenu.nom_complet_du_poursuivis }}</td>
                                        <!-- <td>{{ prevenu['type_identification_du_poursuivis'] }}</td>
                                        <td>{{ prevenu['numéro_didentification_du_poursuivis'] }}</td> -->
                                        <td class="align-middle text-center text-sm">
                                          <div class="d-flex justify-content-between ">
                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="openModalDetailsPrevenu(prevenu)" data-toggle="tooltip"  data-original-title="Edit user">
                                                <i class="fas fa-solid fa-eye text-primary float-right" style="font-size: 21px;"></i>
                                            </a>
                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs " (click)="deletePrevenu(prevenu)" data-toggle="tooltip"  data-original-title="Edit user">
                                              <i class="fas fa-solid fa-trash text-danger float-right" style="font-size: 20px;"></i>
                                          </a>
                                           
       
                                        </div>
                                         </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                
                              </div><br>
                             
                          </ng-template>
                          
                        </div>
                        <label class="fieldlabels">Resumé de l'affaire: </label>
                        <textarea style="height: 220px;" class="form-control"  name="resume_de_l_affaire" ngModel></textarea>


                    </div>
                     <input type="button" name="next" class=" next action-button" value="Suivant" /> <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                </fieldset>

                  <fieldset  class="step4" >
                    <div class="form-card">
                        <div class="row">

                        </div>

                        <div class="row">
                          <div class="col" *ngIf="CAN_SEE_REMARQUE_PROCUREUR" >
                            <label class="fieldlabels">Notification procureur: </label>
                            <select class="custom-select" id="inputGroupSelect01" name="rm_proc" ngModel>
                              <option value="En parler">En parler</option>
                              <option value="M'en parler">M'en parler</option>
                            </select>
                            <label class="fieldlabels">Remarque du procureur: </label>
                            <textarea style="height: 220px;" class="form-control"  name="remarque_du_procureur" ngModel></textarea>
                            <!-- <label class="fieldlabels">Reférence: *</label> <input type="text" class="form-control" name="ref" value="{{data.ref}}" />
                            <label class="fieldlabels">Numéro d'origine: *</label> <input class="form-control" type="text" name="numero" value="{{data.numero_origine}}"/> -->
                          </div> &nbsp; &nbsp;
                          <div class="col d-flex  flex-column justify-content-between " >

                            <div class="row">
                              <div class="d-flex justify-content-between col-md-12 " >
                                <label class="">Piéces du dossier: </label>
                                <!--<a (click)="openModalAddPiece()" class="text-secondary font-weight-bold text-xs float-right" data-toggle="tooltip"  data-original-title="Edit user">
                                  <i class="fas fa-solid fa-plus text-primary"></i> <span class="text-primary"> Ajouter piéce</span>
                              </a>-->
                                <!-- <button class="btn btn-dark float-right" >Ajouter une piece</button> -->
                              </div>
                              <p class="text-center m-auto">Aucune piéce</p>
                              <table class="table fieldlabels mt-3">
                                <thead>
                                <tr style="font-size: 12px;">
                                  <th scope="col">#</th>
                                  <th scope="col">Nom de la piéce</th>
                                  <th scope="col">Numéro courrier</th>
                                  <th scope="col">Visualiser</th>
                                </tr>
                                </thead>
                                <tbody style="font-size: 10px;">
                                <tr *ngFor="let piece of InternalPieces">
                                  <th scope="row">{{piece.name}}</th>
                                  <td>{{piece.nom_du_fichier}}</td>
                                  <td>{{piece.parent}}</td>
                                  <td>
                                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs"  data-toggle="tooltip"
                                       data-original-title="Edit user"  (click)="openModalVisualPiece(piece)" >
                                      <i class="fas fa-solid fa-eye text-primary" style="font-size: 20px;"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                            </div>

                            <!-- <label class="fieldlabels">Nature: *</label>  -->




                          </div>
                        </div>


                    </div>
                    <input type="submit" name="next" class=" action-button" value="Enregistrer"  />
                    <input type="button" name="Précédent" class="previous action-button-previous" value="Précédent" />
                  </fieldset>

                  
              </form>
          </div>


      </div>
  </div>
</div>
