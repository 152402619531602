import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";

@Injectable({
  providedIn: 'root'
})
export class RdvService {

  Doctype_rdv ='Rendez vous'

  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService) {}

 // Jugements

    async getAllRDV(filter=null) {
      let my_rdvs = await this.frapper.GetDoctypes(this.Doctype_rdv,'["*"]',filter).toPromise()
      
      //console.log('My Courriers Principale ===> ', my_courriers)
      return my_rdvs
    }

  async getOneRDV(rdvName) {
    let my_rdv = await this.frapper.GetDoctype(this.Doctype_rdv,rdvName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_rdv
  }

  async nouveauRdv(body) {
    let my_rdv = await this.frapper.CreateDoctype(this.Doctype_rdv,body).toPromise()
    return my_rdv
  }

  async updateSatate(docname,new_state){
    let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_rdv,docname,body).toPromise()
    return result
  }

  async updateRDV(docname,body){
    let result = this.frapper.UpdateDoctype(this.Doctype_rdv,docname,body).toPromise()
    return result
  }

  async assignRoleToUser(body){
    let assignation = await this.assigneService.doAssignation(this.Doctype_rdv,body.name,body.user)
    return assignation
  }

 // ** Jugments
}
