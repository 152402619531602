<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
              <h5 id="heading">Ajout prévenu(e)</h5>
              <!-- <p>Fill all form field to go to next step</p> -->
                <form id="msform" #newdossier="ngForm" (ngSubmit)="AddNewCourrier(newdossier)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Information du prevenu</strong></li>
                     
                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->
                  <fieldset class="step1">
                    <div class="form-card">
                      <div class="row">
                        
                        <div class="col">
                          <label class="fieldlabels">Type d'identification du prévenu: </label>
                          <select  class="custom-select" id="inputGroupSelect01" name="type_identification_du_poursuivis" ngModel>
                            <option *ngFor="let id of identification_type" value="{{id.name}}" >{{id.type}}</option>
                          </select>
                           
                            <label class="fieldlabels">Numéro d'identification prévenu: </label>
                                <input type="text" class="form-control" name="numéro_didentification_du_poursuivis" ngModel/>
                            <label class="fieldlabels">Nom complet prévenu: </label>
                                <input type="text" class="form-control" name="nom_complet_du_poursuivis" ngModel/>
                            <label class="fieldlabels">Lieu de naissance prévenu: </label>
                            <input type="text" class="form-control" name="lieu_de_naissance_du_prévenu" ngModel/>
                        </div> &nbsp; &nbsp;
                        
                          <div class="col d-flex  flex-column justify-content-between " >
                            <label class="fieldlabels">Date de naissance prévenu: </label>
                            <input type="date" class="form-control" name="date_de_naissance_du_prévenu" ngModel/>
                            <label class="fieldlabels">Adresse complète du prévenu </label>
                                <input class="form-control" type="text" name="addresse_complete_du_poursuivis" ngModel/>

                            <label class="fieldlabels">Nom complet de la mére du prévenu </label>
                            <input class="form-control" type="text" name="nom_complet_de_la_mere_du_prévenu" ngModel/>
                            <label class="fieldlabels">Nom complet du pére du prévenu </label>
                            <input class="form-control" type="text" name="nom_complet_du_pere_du_prévenu" ngModel/>
                          </div>
                      
                        
                      </div>

                      <div class="row" *ngIf="type === 'jugement'">
                        
                        <div class="col">
                          
                            <label class="fieldlabels">Nationnalité du prevenu: </label>
                            <select class="custom-select" id="inputGroupSelect01" name="nationalité" ngModel required>
                              <option value="Sénégalais(e)">Sénégalais(e)</option>
                              <option value="Gambien(ne)">Gambien(ne)</option>
                              <option value="Malien(ne)">Malien(ne)</option>
                              <option value="Mauritanien(ne)">Mauritanien(ne)</option>
                              <option value="Guinéen(ne)">Guinéen(ne)</option>
                              <option value="Autre">Autre</option>
                            </select>
                        </div> &nbsp; &nbsp;
                        
                          <div class="col d-flex  flex-column justify-content-between " >
                      

                                <label class="fieldlabels">Pays de résidence du prévenu: </label>
                                  <select class="custom-select" id="inputGroupSelect01" name="pays_de_residence" ngModel>
                                    <option value="SENEGAL">SENEGAL</option>
                                    <option value="GAMBIE">GAMBIE</option>
                                    <option value="AUTRE">AUTRE</option>
                                  </select>

                                  <label class="fieldlabels">Profession du prévenu: </label>
                                  <input class="form-control" type="text" name="profession" ngModel/>
                          
                          </div>
                      
                        
                      </div>

                  </div>  <input type="submit" name="next" class=" action-button" value="Enregistrer"  />
                  <input type="button" name="Précédent" class="previous action-button-previous" value="Fermer" (click)="hideModal()"/>
                  </fieldset >

              </form>
          </div>


      </div>
  </div>
</div>
