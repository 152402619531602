import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";

@Injectable({
  providedIn: 'root'
})
export class EnrollementService {

  Doctype_enrolement ='E-Role'
  Doctype_chambres ='Chambres'
  Doctype_infractions ='Infractions'

  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService) {}


  async getnumeroRole() {
    let me = localStorage.getItem('me')
    const body= {}
    //console.log('body ==> ',body)
    let role = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_numero_role_general',body).toPromise()
    return role
  }



 // Roles
  async getAllMyRole(filter =null) {
    let my_roles = await this.frapper.GetDoctypes(this.Doctype_enrolement,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_roles
  }
  async getAllMyChambre(filter =null) {
    let my_chambres = await this.frapper.GetDoctypes(this.Doctype_chambres,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
  }
  async getOneChambre(name) {
    let my_chambres = await this.frapper.GetDoctype(this.Doctype_chambres,name,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
  }
  async getAllInfractions(filter =null) {
    let my_chambres = await this.frapper.GetDoctypes(this.Doctype_infractions,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_chambres
  }

  async getOneRole(roleName) {
    let my_role = await this.frapper.GetDoctype(this.Doctype_enrolement,roleName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_role
  }

  async nouveauRole(body) {
    let new_role = await this.frapper.CreateDoctype(this.Doctype_enrolement,body).toPromise()
    return new_role
  }

  async updateRole(docname,body){
    let result = this.frapper.UpdateDoctype(this.Doctype_enrolement,docname,body).toPromise()
    return result
  }
  async updateSatate(docname,new_state){
    let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_enrolement,docname,body).toPromise()
    return result
  }

  async assignRoleToUser(body){
    let assignation = await this.assigneService.doAssignation(this.Doctype_enrolement,body.name,body.user)
    return assignation
  }

 // ** Dossier
}
