import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { FrapperService } from '../../services/frapper/frapper.service';
import { Router } from '@angular/router';
import { AlertesService } from '../../services/public/alertes.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private frapper: FrapperService,private _route: Router, private alerte:AlertesService ) {}
  

//   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (request.url.includes('/auth')) {
//       return next.handle(request); // Passer outre l'intercepteur
//     }else{
//        let myToken = this.auth.getToken()
//     // return next.handle(request);
//     const headers = new HttpHeaders()
   
//     .append('Authorization', myToken["type"]+" "+myToken["token"]);
//     const modifRequest = request.clone({headers});
//     return next.handle(modifRequest)
//     }
   
//   }
intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/auth')) {
      return next.handle(request); // Passer outre l'intercepteur
    } else {
      let myToken = this.frapper.getToken();
      if (myToken) { // Vérifier que myToken est défini et qu'il a une propriété 'type'
        const headers = new HttpHeaders()
          .append('Authorization', `Basic ${myToken}`);
        const modifRequest = request.clone({ headers });
        return next.handle(modifRequest);
      } else {
        
        console.log('token expiredddddd');
        
        // Vous pouvez également simplement transmettre la requête sans modifier les en-têtes d'authentification
        return next.handle(request);
      }
    }
  }
}
