import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import { EditTextComponent } from '../edit-text/edit-text.component';
import { VisualizeJugementComponent } from '../visualize-jugement/visualize-jugement.component';
import * as $ from 'jquery';
import {RdvService} from "../../services/frapper/rdv.service";
import {CustomService} from "../../services/frapper/custom.service";

@Component({
  selector: 'app-view-detail-rendezvous',
  templateUrl: './view-detail-rendezvous.component.html',
  styleUrls: ['./view-detail-rendezvous.component.scss']
})
export class ViewDetailRendezvousComponent {

  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  infractions: any
  enrolle:any = {
    "type_daffaire": "Pénale",
    "numero_du_jugement": "None",
    "chambre": "Aucune",
    "date_du_jugement": "None"
  };
  jugement:any;
  modalRef!: BsModalRef;
  selectedInfractions: string[] = [];
  Infractions: any[] = [];
  Amendes: any[] = [];
  Emprisonements: any[] = [];
  ContrainteParCorps: any[] = [];
  affiliations: any[] = [];

  TypeDeCondamnation: any[] = [];
  CategorieDeCondamnation: any[] = [];

  numero_du_jugement=null
  Decision_Judiciaire = null
  Date_decision = null
  Categorie_Decision = null
  showDefault: boolean = true;
  showFactum: boolean = false;


  ALLOWED_DECISION_FINAL = false
  ALLOWED_SAVE_DECISION = false
  profil
  currentDate = new Date();
   formattedDate : Date;
    dateSpecifiqueObj: Date;
  activeUpdate: boolean = false;
  me

  constructor(private modalService: BsModalService,public options: ModalOptions,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
    private jugementService: JugementsService,
    private datePipe: DatePipe,
              private customService :CustomService,
    private role:EnrollementService,
    private rdvService:RdvService,
    private penalService: PenalCourrierService,
    private enrollementService: EnrollementService) {
    this.data = options.initialState.data

    this.profil = localStorage.getItem('profil')
    this.me = localStorage.getItem("me")
    this.me =JSON.parse(this.me)
    let year = this.currentDate.getFullYear();
    let month = ('0' + (this.currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
    let day = ('0' + this.currentDate.getDate()).slice(-2);

    // Format the date as YYYY-MM-DD
    // this.formattedDate= new Date(`${year}-${month}-${day}`) ;
    

    
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }




  onSubmit(form: NgForm) {
    // Initialiser un tableau pour stocker les condamnations

    //console.log(form.value)

    this.ngxService.start()

    // Construire l'objet de contenu final avec toutes les données
    const contentBody = {
      "date": form.value.date_rv,
      "heure_debut": form.value.h_debut,
      "heure_fin": form.value.h_fin,
      "nom_demandeur": form.value.nom_demandeur,
      "email": form.value.email,
      "telephone": form.value.telephone,
      "information": form.value.information_rv,
    };

    // Afficher l'objet de contenu dans la console
    console.log({'content body': contentBody});
    this.rdvService.updateRDV(this.data.name,contentBody).then(res=>{
      //Send demandeur
      let message = 'Mise a a jour du rendez-vous avec '+this.me['full_name']+', Programmé pour : '+contentBody.date+' de : '+contentBody.heure_debut+' a: '+contentBody.heure_fin+'. Sujet = '+contentBody.information
      this.customService.SendEmailNotification('Mise a jour du rendez-vous avec '+this.me['full_name']+' du '+this.data.date+' de '+this.data.heure_debut+'/'+this.data.heure_fin,contentBody.email,message).then(res=>{
        console.log('Notif Send')
      })
      //Send Demandeur
      let message_2 = 'Nouveau rendez-vous avec '+contentBody.nom_demandeur+', Programmé pour : '+contentBody.date+' de : '+contentBody.heure_debut+' a: '+contentBody.heure_fin+'. Sujet = '+contentBody.information
      this.customService.SendEmailNotification('Mise a jour du rendez-vous avec '+this.data.nom_demandeur+' du '+this.data.date+' de '+this.data.heure_debut+'/'+this.data.heure_fin,this.me['name'],message_2).then(res=>{
        console.log('Notif Send')
      })

      this.openModalSuccessDialog("RV Mis a jours avec succé!")
      this.ngxService.stop()
    },error=>{
      this.alerte.errorNotification('Rendez-Vous','Impossible de mettre a jour ce rendez vous')
      this.ngxService.stop()
    })


  }

  openModalSuccessDialog(message: string) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    setTimeout(() => {
      if (this.modalService) {
        this.modalService.hide();
      }
    }, 1000);
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }
  hidde() {
    if (this.modalService) {
      this.modalService.hide();
    }
  }

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });



  }

  comparerDatesSansHeure(dateObjet, dateActuel) {
    // Comparaison des années
    let yearComparison = dateObjet.getFullYear() - dateActuel.getFullYear();
  
  
    // Comparaison des mois
    let monthComparison = dateObjet.getMonth() - dateActuel.getMonth();

    // Comparaison des jours
    let dayComparison = dateObjet.getDate() - dateActuel.getDate();
    
    if (monthComparison==0 && dayComparison>=0 ) {
      return true;
    }else if (monthComparison<0) {
      return true;
    }else{
      return false;
    }
  }
  
  // Utilisation de la fonction pour comparer date1 et date2
  

  async ngOnInit() {
    this.formattedDate= new Date() ;
     this.dateSpecifiqueObj = new Date(this.data.date);
    var self = this;
    this.activeUpdate =  this.comparerDatesSansHeure(this.dateSpecifiqueObj,this.formattedDate)



  }
}
