<div class="container-fluid ">
  <div class="row justify-content-center">
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center ">

        <!-- formulaire courrier entrant -->
          <div class="card" >
              <h5 id="heading">Détails prévenu(e)</h5>
              <!-- <p>Fill all form field to go to next step</p> -->
                <form id="msform" #newdossier="ngForm" (ngSubmit)="AddNewCourrier(newdossier)">
                  <!-- progressbar -->
                  <ul id="progressbar">
                      <li class="active" id="step1"><strong>Information du prevenu</strong></li>
                     
                  </ul>
                  <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> <br> <!-- fieldsets -->
                  <fieldset class="step1">
                    <div class="form-card">
                      <div class="row">
                        
                        <div class="col">
                          <label class="fieldlabels">Type d'identification du prévenu: </label>
                            <select class="custom-select" id="inputGroupSelect01" [(ngModel)]="data.type_identification_du_poursuivis" name="type_identification_du_poursuivis" >
                              <option value="{{data.type_identification_du_poursuivis}}">{{data.type_identification_du_poursuivis}}</option>
                            </select>
                            <label class="fieldlabels">Numéro d'identification prévenu: </label>
                                <input type="text" class="form-control" [(ngModel)]="data['numéro_didentification_du_poursuivis']" name="numéro_didentification_du_poursuivis" />
                            <label class="fieldlabels">Nom complet prévenu: </label>
                                <input type="text" class="form-control" [(ngModel)]="data.nom_complet_du_poursuivis" name="nom_complet_du_poursuivis" />
                            <label class="fieldlabels">Lieu de naissance prévenu: </label>
                            <input type="text" class="form-control" [(ngModel)]="data['lieu_de_naissance_du_prévenu']" name="lieu_de_naissance_du_prévenu" />
                        </div> &nbsp; &nbsp;
                        
                          <div class="col d-flex  flex-column justify-content-between " >
                            <label class="fieldlabels">Date de naissance prévenu: </label>
                            <input type="date" class="form-control" [(ngModel)]="data['date_de_naissance_du_prévenu']" name="date_de_naissance_du_prévenu" />
                            <label class="fieldlabels">Adresse complète du prévenu </label>
                                <input class="form-control" type="text" [(ngModel)]="data['addresse_complete_du_poursuivis']" name="addresse_complete_du_poursuivis" />

                            <label class="fieldlabels">Nom complet de la mére du prévenu </label>
                            <input class="form-control" type="text" value="{{data['nom_complet_de_la_mere_du_prévenu']}}" name="nom_complet_de_la_mere_du_prévenu" />
                            <label class="fieldlabels">Nom complet du pére du prévenu </label>
                            <input class="form-control" type="text" [(ngModel)]="data['nom_complet_du_pere_du_prévenu']" name="nom_complet_du_pere_du_prévenu" />
                          </div>
                      
                        
                      </div>

                  </div>  <input type="submit" name="next" class=" action-button" value="Modifier"  />
                  <input type="button" name="Précédent" class="previous action-button-previous" value="Fermer" (click)="hideModal()"/>
                  </fieldset >

              </form>
          </div>


      </div>
  </div>
</div>
