import { Component, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-details-prevenu',
  templateUrl: './details-prevenu.component.html',
  styleUrls: ['./details-prevenu.component.scss']
})
export class DetailsPrevenuComponent {
  @Output() newPrevenuAdded = new EventEmitter<any>();
  data:any;
  constructor(
    public options: ModalOptions,
    public bsModalRef: BsModalRef
    ) {
      this.data = options.initialState.data
      console.log('the daaatttaaa=> ', this.data);
      
    }

    AddNewCourrier(formData: NgForm){
      this.newPrevenuAdded.emit(formData.value);
      this.bsModalRef.hide(); 
      
    }
    hideModal(){
      this.bsModalRef.hide(); 
    }
  
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
