import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
// import { MyCONST } from 'src/app/CONST/my_const';

@Injectable({
  providedIn: 'root'
})
export class PenalCourrierService {
  donnees: any;
  chambres: any;
  data_substuties: any;
  Dossiersenrolles: any;
  data_interneCourriers: any;
  data_principalCourriers: any;
  donneesChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  chambresChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SubstutiesChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  CourrierIntChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  DossierEnrolleChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SelectedChambreChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  SelectedInfractionChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);


   selectedDossier ;
   selectedJugement ;
   selectedChambre ;
   selectedInf ;
   selectedDate ;

   profiles_user: any;
  // selectedDossier = this.selectedDossier;
  courriers: any = [
     {
      "id": 'AR-01-2024',
      "name": "Alhassane Faye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",
      "assign": "insfotsas@gmail.com",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-02-2024',
      "name": "Francois Diop",
      "registre": "courrier d'arrive",
      "status": "instruction",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "non",
      "assign": "insfotsas@gmail.com",


      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "hhh",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "jjj",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",
    },
    {
      "id": 'AR-03-2024',
      "name": "John cool",
      "registre": "courrier d'arrive",
      "status": "enrollement",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",
      "assign": "insfotsas@gmail.com",


      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "lll",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "ooo",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",
    },
    {
      "id": 'AR-04-2024',
      "name": "Mariam sarr",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis et notifications",
      "ref": "oui",
      "assign": "insfotsas@gmail.com",


      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "qqq",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "aaa",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",
    },
    {
      "id": 'AR-05-2024',
      "name": "Leyti Ndiaye",
      "registre": "courrier arrivé",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",
      "assign": "insfotsas@gmail.com",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-06-2024',
      "name": "Omar Ba",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-07-2024',
      "name": "Ousmane fall",
      "registre": "courrier arrivé",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-08-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier arrivé",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-09-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-10-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-11-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-12-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier arrivé",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-13-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-14-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier arrivé",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
    {
      "id": 'AR-15-2024',
      "name": "Abdou Ndiaye",
      "registre": "courrier depart",
      "status": "imputation",
      "date": "23/04/2023",
      "nature": "Avis notif.",
      "ref": "oui",

      "classif": "",
      "information_ref": "",
      "numero_origine": "",
      "type_identif": "CNI",
      "n_identif": "",
      "pays": "Senegal",
      "n_origine": "",
      "region": "Dakar",
      "adresse": "pikine",
      "redaction": "CNI",

      "p_type_identif": "CNI",
      "p_n_identif": "",
      "p_nom": "zzz",
      "p_adress": "",
      "pv_type_identif": "CNI",
      "pv_n_identif": "",
      "pv_nom": "",
      "pv_adress": "",

      "notif": "En parler",
      "remarque": "",


    },
  ]


  list_naturalisations: any = [
    {
      "id": 1,
      "statut": "Validée",
      "numero_de_courrier": 240,
      "date_d_arrivée" : "2024-04-16",
      "numero_de_dossier": 3565,
      "nom": "Diallo",
      "prenom": "Hassim",
      "date_de_demande": "2024-04-18",
      "date_de_naissance": "2002-04-18",
      "lieu_de_naissance": "T.azur",
      "pays_dorigine": "Senegal",
      "sexe": "Homme",
      "numero_de_telephone": "772552431",
      "profession": "informaticien",
      "adresse_de_lemployeur": "Dakar",
      "prenom_et_nom_du_pere": "YYYY",
      "prenom_et_nom_de_la_mere": "SSSS",
      "nationalite_d_origine": "Senegalais",
    },
    {
      "id": 2,
      "statut": "En attente",
      "numero_de_courrier": 250,
      "date_d_arrivée" : "2024-04-20",
      "numero_de_dossier": 3765,
      "nom": "Diallo",
      "prenom": "Hassim",
      "date_demande": "2024-04-18",
      "date_naissance": "2002-04-18",
      "lieu_naissance": "T.azur",
      "pays_dorigine": "Senegal",
      "sexe": "Homme",
      "numero_telephone": "772552431",
      "profession": "informaticien",
      "adresse_de_lemployeur": "Dakar",
      "prenom_et_nom_du_pere": "YYYY",
      "prenom_et_nom_de_la_mere": "SSSS",
      "nationalite_d_origine": "Senegalais",
    },
    {
      "id": 3,
      "statut": "Validé",
      "numero_de_courrier": 340,
      "date_d_arrivée" : "2024-05-16",
      "numero_de_dossier": 2341,
      "nom": "Diallo",
      "prenom": "Hassim",
      "date_demande": "2024-04-18",
      "date_naissance": "2002-04-18",
      "lieu_naissance": "T.azur",
      "pays_dorigine": "Senegal",
      "sexe": "Homme",
      "numero_telephone": "772552431",
      "profession": "informaticien",
      "adresse_de_lemployeur": "Dakar",
      "prenom_et_nom_du_pere": "YYYY",
      "prenom_et_nom_de_la_mere": "SSSS",
      "nationalite_d_origine": "Senegalais",
    },
    {
      "id": 4,
      "statut": "Validé",
      "numero_de_courrier": 140,
      "date_d_arrivée" : "2024-02-27",
      "numero_de_dossier": 4565,
      "nom": "Diallo",
      "prenom": "Hassim",
      "date_demande": "2024-04-18",
      "date_naissance": "2002-04-18",
      "lieu_naissance": "T.azur",
      "pays_dorigine": "Senegal",
      "sexe": "Homme",
      "numero_telephone": "772552431",
      "profession": "informaticien",
      "adresse_de_lemployeur": "Dakar",
      "prenom_et_nom_du_pere": "YYYY",
      "prenom_et_nom_de_la_mere": "SSSS",
      "nationalite_d_origine": "Senegalais",
    }
  ];



  _dossiers: any = [
    {
        "name": "3a7babc961",
        "owner": "a_courrier@justice.sn",
        "creation": "2024-01-18 16:44:50.654192",
        "modified": "2024-01-18 16:44:50.654192",
        "modified_by": "a_courrier@justice.sn",
        "docstatus": 0,
        "idx": 0,
        "workflow_state": "Sauvegarder",
        "classification": null,
        "registre": "Courrier Arrivé",
        "numero_courrier": null,
        "amended_from": null,
        "nature": "Citation Directe a la requete de la partie civil",
        "reference": "Non",
        "numero_dorigine": null,
        "information_de_la_reference": null,
        "type_identification": "CNI",
        "prenom": "Amadou",
        "numero_identification": "333333333",
        "nom": "Diouf",
        "pays": "SENEGAL",
        "region": "Dakar",
        "adresse": null,
        "numero_d_origine": null,
        "redaction": null,
        "type_identf_expediteur": "CNI",
        "num_identif_expediteur": null,
        "type_identification_plaignant": null,
        "numéro_didentification_plaignant": null,
        "nom_complet_du_plaignant": null,
        "addresse_complete_du_plaignant": null,
        "type_identification_du_poursuivis": null,
        "numéro_didentification_du_poursuivis": null,
        "nom_complet_du_poursuivis": null,
        "addresse_complete_du_poursuivis": null,
        "resume_de_l_affaire": null,
        "link_fonf": null,
        "rm_proc": null,
        "remarque_du_procureur": null,
        "small_text_sszx": null,
        "d_type_identification": null,
        "d_numero_identification": null,
        "nationalité_du_demadeur": null,
        "nom_du_demandeur": null,
        "date_de_naissance_du_demandeur": null,
        "type_identification_du_demandé": null,
        "numéro_didentification_du_demandé": null,
        "nationalité_du_demandé": null,
        "désignation_du_demandé": null,
        "date_de_naissance_du_demandé": null,
        "detail_courrier_v": null,
        "courrier_interne": null
    },
    {
        "name": "91a7122aa4",
        "owner": "r_courrier@justice.sn",
        "creation": "2024-01-18 16:33:31.052988",
        "modified": "2024-01-18 16:33:31.052988",
        "modified_by": "r_courrier@justice.sn",
        "docstatus": 0,
        "idx": 0,
        "workflow_state": "Sauvegarder",
        "classification": null,
        "registre": "Courrier Arrivé",
        "numero_courrier": null,
        "amended_from": null,
        "nature": "Plainte",
        "reference": "Non",
        "numero_dorigine": null,
        "information_de_la_reference": null,
        "type_identification": "CNI",
        "prenom": "Fatou",
        "numero_identification": "222222222",
        "nom": "Diagne",
        "pays": "SENEGAL",
        "region": null,
        "adresse": null,
        "numero_d_origine": null,
        "redaction": "Une plainte déposée ",
        "type_identf_expediteur": "CNI",
        "num_identif_expediteur": null,
        "type_identification_plaignant": null,
        "numéro_didentification_plaignant": null,
        "nom_complet_du_plaignant": null,
        "addresse_complete_du_plaignant": null,
        "type_identification_du_poursuivis": null,
        "numéro_didentification_du_poursuivis": null,
        "nom_complet_du_poursuivis": null,
        "addresse_complete_du_poursuivis": null,
        "resume_de_l_affaire": null,
        "link_fonf": null,
        "rm_proc": null,
        "remarque_du_procureur": null,
        "small_text_sszx": null,
        "d_type_identification": null,
        "d_numero_identification": null,
        "nationalité_du_demadeur": null,
        "nom_du_demandeur": null,
        "date_de_naissance_du_demandeur": null,
        "type_identification_du_demandé": null,
        "numéro_didentification_du_demandé": null,
        "nationalité_du_demandé": null,
        "désignation_du_demandé": null,
        "date_de_naissance_du_demandé": null,
        "detail_courrier_v": null,
        "courrier_interne": null
    },
    {
      "name": "3a7babc961",
      "owner": "a_courrier@justice.sn",
      "creation": "2024-01-18 16:44:50.654192",
      "modified": "2024-01-18 16:44:50.654192",
      "modified_by": "a_courrier@justice.sn",
      "docstatus": 0,
      "idx": 0,
      "workflow_state": "Sauvegarder",
      "classification": null,
      "registre": "Courrier Arrivé",
      "numero_courrier": null,
      "amended_from": null,
      "nature": "Citation Directe a la requete de la partie civil",
      "reference": "Non",
      "numero_dorigine": null,
      "information_de_la_reference": null,
      "type_identification": "CNI",
      "prenom": "Amadou",
      "numero_identification": "333333333",
      "nom": "Diouf",
      "pays": "SENEGAL",
      "region": "Dakar",
      "adresse": null,
      "numero_d_origine": null,
      "redaction": null,
      "type_identf_expediteur": "CNI",
      "num_identif_expediteur": null,
      "type_identification_plaignant": null,
      "numéro_didentification_plaignant": null,
      "nom_complet_du_plaignant": null,
      "addresse_complete_du_plaignant": null,
      "type_identification_du_poursuivis": null,
      "numéro_didentification_du_poursuivis": null,
      "nom_complet_du_poursuivis": null,
      "addresse_complete_du_poursuivis": null,
      "resume_de_l_affaire": null,
      "link_fonf": null,
      "rm_proc": null,
      "remarque_du_procureur": null,
      "small_text_sszx": null,
      "d_type_identification": null,
      "d_numero_identification": null,
      "nationalité_du_demadeur": null,
      "nom_du_demandeur": null,
      "date_de_naissance_du_demandeur": null,
      "type_identification_du_demandé": null,
      "numéro_didentification_du_demandé": null,
      "nationalité_du_demandé": null,
      "désignation_du_demandé": null,
      "date_de_naissance_du_demandé": null,
      "detail_courrier_v": null,
      "courrier_interne": null
  },
  {
    "name": "3a7babc961",
    "owner": "a_courrier@justice.sn",
    "creation": "2024-01-18 16:44:50.654192",
    "modified": "2024-01-18 16:44:50.654192",
    "modified_by": "a_courrier@justice.sn",
    "docstatus": 0,
    "idx": 0,
    "workflow_state": "Sauvegarder",
    "classification": null,
    "registre": "Courrier Arrivé",
    "numero_courrier": null,
    "amended_from": null,
    "nature": "Citation Directe a la requete de la partie civil",
    "reference": "Non",
    "numero_dorigine": null,
    "information_de_la_reference": null,
    "type_identification": "CNI",
    "prenom": "Amadou",
    "numero_identification": "333333333",
    "nom": "Diouf",
    "pays": "SENEGAL",
    "region": "Dakar",
    "adresse": null,
    "numero_d_origine": null,
    "redaction": null,
    "type_identf_expediteur": "CNI",
    "num_identif_expediteur": null,
    "type_identification_plaignant": null,
    "numéro_didentification_plaignant": null,
    "nom_complet_du_plaignant": null,
    "addresse_complete_du_plaignant": null,
    "type_identification_du_poursuivis": null,
    "numéro_didentification_du_poursuivis": null,
    "nom_complet_du_poursuivis": null,
    "addresse_complete_du_poursuivis": null,
    "resume_de_l_affaire": null,
    "link_fonf": null,
    "rm_proc": null,
    "remarque_du_procureur": null,
    "small_text_sszx": null,
    "d_type_identification": null,
    "d_numero_identification": null,
    "nationalité_du_demadeur": null,
    "nom_du_demandeur": null,
    "date_de_naissance_du_demandeur": null,
    "type_identification_du_demandé": null,
    "numéro_didentification_du_demandé": null,
    "nationalité_du_demandé": null,
    "désignation_du_demandé": null,
    "date_de_naissance_du_demandé": null,
    "detail_courrier_v": null,
    "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
{
  "name": "3a7babc961",
  "owner": "a_courrier@justice.sn",
  "creation": "2024-01-18 16:44:50.654192",
  "modified": "2024-01-18 16:44:50.654192",
  "modified_by": "a_courrier@justice.sn",
  "docstatus": 0,
  "idx": 0,
  "workflow_state": "Sauvegarder",
  "classification": null,
  "registre": "Courrier Arrivé",
  "numero_courrier": null,
  "amended_from": null,
  "nature": "Citation Directe a la requete de la partie civil",
  "reference": "Non",
  "numero_dorigine": null,
  "information_de_la_reference": null,
  "type_identification": "CNI",
  "prenom": "Amadou",
  "numero_identification": "333333333",
  "nom": "Diouf",
  "pays": "SENEGAL",
  "region": "Dakar",
  "adresse": null,
  "numero_d_origine": null,
  "redaction": null,
  "type_identf_expediteur": "CNI",
  "num_identif_expediteur": null,
  "type_identification_plaignant": null,
  "numéro_didentification_plaignant": null,
  "nom_complet_du_plaignant": null,
  "addresse_complete_du_plaignant": null,
  "type_identification_du_poursuivis": null,
  "numéro_didentification_du_poursuivis": null,
  "nom_complet_du_poursuivis": null,
  "addresse_complete_du_poursuivis": null,
  "resume_de_l_affaire": null,
  "link_fonf": null,
  "rm_proc": null,
  "remarque_du_procureur": null,
  "small_text_sszx": null,
  "d_type_identification": null,
  "d_numero_identification": null,
  "nationalité_du_demadeur": null,
  "nom_du_demandeur": null,
  "date_de_naissance_du_demandeur": null,
  "type_identification_du_demandé": null,
  "numéro_didentification_du_demandé": null,
  "nationalité_du_demandé": null,
  "désignation_du_demandé": null,
  "date_de_naissance_du_demandé": null,
  "detail_courrier_v": null,
  "courrier_interne": null
},
  
  ]


  substuties: any = [
    {
     "id": 0,
     "name": "Anne Ibrahim",
     "trubinal": "Dakar",
     "tel": "771234567",
     "email": "insoftsas@gmail.com",

   },
   {
    "id": 1,
    "name": "Sonko Ousmane",
    "trubinal": "Ziguinchor",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },

   {
    "id": 2,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id":3,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 4,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 5,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 6,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 7,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 8,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 9,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 10,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 11,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 12,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 13,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
   {
    "id": 14,
    "name": "Faye Diomaye",
    "trubinal": "Fatick",
    "tel": "771234567",
    "email": "insoftsas@gmail.com",
   },
 ]


//  courriers internes

 courriersInternes: any = [
  {
   "id": 0,
   "name": "Anne Ibrahim",
   "fonction": "juge",
   "message": "Ordonnance aux fins de non lieu",
   "titre": "Ordonnance aux fins de non lieu",
   "numDossier": "AR-01-2024",

 },
 {
  "id": 1,
  "name": "Diagne Seye",
  "fonction": "Informaticien",
  "message": "Ordonnance aux fins de renvoie",
   "titre": "Ordonnance aux fins de renvoie",
   "numDossier": "AR-03-2023",
 },
 {
  "id": 2,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
   "titre": "Ordonnance aux fins de renvoie",
   "numDossier": "AR-05-2022",
 },
 {
  "id":3,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien",
 },
 {
  "id": 4,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 5,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 6,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 7,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 8,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 9,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 10,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 11,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 12,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 13,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
 {
  "id": 14,
  "name": "Faye Alhassane",
  "fonction": "greffier",
  "message": "Bonjour procureur,vous allez bien"
 },
]

// dossiers enrolles

 _Dossiersenrolles: any = [
  {
    "id": 1,
    "numero": "AR-01-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 2,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 3,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 4,
    "numero": "AR-09-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 4,
    "numero": "AR-03-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 6,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 7,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 8,
    "numero": "AR-06-2024",
    "date": "2024/03/18",
    "chambre": {
      "id": 2,

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 9,
    "numero": "AR-09-2024",
    "date": "16/03/2024",
    "chambre": {
      "id": 1,

      "name": "chambre 1",
       "libelle": "crime",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
  {
    "id": 10,
    "numero": "AR-03-2024",
    "date": "28/03/2024",
    "chambre": {
      "id": 2,  

      "name": "chambre 2",
       "libelle": "cassation",
       "membres": ['Ibrahim Anne','Moustapha Diop'],

     },


  },
]


// courriers principales

_CourrierPrincipals: any = [
  {
    "id": 1,
    "numero": "AA-08-2024",
    "date": "22-fevr-2024",
    "fullname":"Anne ibrahim",
    "registre":"Courrier depart",
    "nature":"Plainte",


  },
  {
    "id": 2,
    "numero": "AA-02-2024",
    "date": "25-avr-2024",
    "fullname":"Diouf Jean",
    "registre":"Courrier arrivé",
    "nature":"Notification",


  },

  {
    "id": 3,
    "numero": "AA-07-2024",
    "date": "25-avr-2024",
    "fullname":"Seck Modou",
    "registre":"Courrier arrivé",
    "nature":"Avis & notif",


  },
]

// chambres

_chambres: any = [
  {
   "id": 0,
   "name": "chambre 1",
   "libelle": "cassation",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Vendredi', 'Mardi'],
   "max":'10'

 },
 {
  "id": 1,

  "name": "chambre 2",
   "libelle": "crime",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Mercredi', 'Jeudi'],
   "max":'10'

 },
 {
  "id": 2,
  "name": "chambre 3",
  "libelle": "Delits",
  "membres": ['Ibrahim Anne','Moustapha Diop'],
  "jour_travail": ['Mardi', 'Vendredi'],
  "max":'10'
 },
 {
  "id":3,
  "name": "chambre 4",
   "libelle": "judiciaires",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Lundi', 'Jeudi'],
   "max":'10'
 },
 {
  "id":4,
  "name": "chambre 5",
   "libelle": "yyyy",
   "membres": ['Ibrahim Anne','Moustapha Diop'],
   "jour_travail": ['Mercredi', 'Vendredi'],
   "max":'10'
 },

];

// affaires par chambres

_affaires_chambre: any = [
  {
   "id": 1,
   "chambre": "1 ère Chambre Correctionnelle",
   "jours": [
    {
      "Lundi":{
        "date":'03-06-2024',
        "nbre_enrollements":'20',
      },
    
    }

   ]


 },

 {
  "id": 2,
  "chambre": "3 ème Chambre Correctionnelle",
  "jours": [
   {
     "Jeudi":{
       "date":'13-06-2024',
       "nbre_enrollements":'20',
     },
     "Vendredi":{
      "date":'15-05-2024',
      "nbre_enrollements":'4',
    },
   
   }

  ]


},


]

// list infractions

infractions: any = [
  {
   "id": 1,
   "name": "vol",

 },
 {
  "id": 2,
  "name": "crime",

},
{
  "id": 3,
  "name": "diffamation",

},
{
  "id": 4,
  "name": "viol",

},


]

// get

// juges

juges: any = [
  {
   "id": 0,
   "numero": "JUGE01",
   "name": "juge1",
  

 },
 {
  "id": 1,
  "numero": "JUGE02",
  "name": "juge2",
 

 },
 {
  "id": 2,
  "numero": "JUGE03",
  "name": "juge3",
  
 },
 {
  "id":3,
  "numero": "JUGE04",
  "name": "juge4",
 },
 {
  "id":4,
  "numero": "JUGE05",
  "name": "juge5",
 },

];


//


  constructor(private http: HttpClient) { }

  getCourrier(){
    this.donnees = this.courriers;
    this.donneesChange.next(this.donnees);
    return this.donnees;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  getNaturalisations(){
    
    return this.list_naturalisations;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  getJuges(){
   
    return this.juges;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }
  getDossiers(){
    this.donnees = this._dossiers;
    this.donneesChange.next(this.donnees);
    return this.donnees;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  getChambres(){
    this.chambres = this._chambres;
    this.chambresChange.next(this.chambres);
    return this.chambres;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }


  getInfractions(){
   
    return this.infractions;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }
  getSubstuties(){
    this.data_substuties = this.substuties;
    this.SubstutiesChange.next(this.data_substuties);
    return this.data_substuties;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  getCourriersInternes(){
    this.data_interneCourriers = this.courriersInternes;
    this.CourrierIntChange.next(this.data_interneCourriers);
    return this.data_interneCourriers;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }
  getCourriersPrincipals(){
    this.data_principalCourriers = this._CourrierPrincipals;
    // this.CourrierIntChange.next(this.data_principalCourriers);
    return this.data_principalCourriers;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  getDossiersEnrolles(){
    this.Dossiersenrolles = this._Dossiersenrolles;
    this.DossierEnrolleChange.next(this.Dossiersenrolles);
    return this.Dossiersenrolles;

    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  // trouver un objet enrollement a partir du numero de dossier
  findEnrollement(numero: string): any {
    return this._Dossiersenrolles.find(enrollement => enrollement.numero === numero);
  }


  hiddeModal(modalRef: BsModalRef){
    modalRef.hide();
    // return this.http.get<any>(this.API_URL + 'public/currencies/' )
  }

  findDossier(id: string): any {
    return this.courriers.find(courrier => courrier.id === id);
  }

  // enrollement

  setSelectedDossier(dossier: any) {
    this.selectedDossier = dossier;

  }

  setSelectedJugement(dossier: any) {
    this.selectedJugement = dossier;
    console.log('selected jugment '+ this.selectedJugement);
    

  }

  setSelectedChambre(dossier: any) {
   
      this.selectedChambre = dossier;
    console.log({'is selected chambre ': this.selectedChambre});
    
    this.SelectedChambreChange.next(this.selectedChambre);
    
    

  }

  setSelectedInfraction(infraction: any) {
    this.selectedInf = infraction;
    this.SelectedInfractionChange.next(this.selectedInf);
    console.log({'infraction': this.selectedInf});

  }

  setSelectedDate(date: any) {
    this.selectedDate = date;
    console.log({'hhi': this.selectedDate[0]});
    return this.selectedDate

  }



  // filter courriers
  filterCourrier(categorie: string, key: string) {
    if (categorie && key) {
      // Filtrer les courriers en fonction de la catégorie
      const courriersFiltres = this.courriers.filter(courrier => {
        if (categorie === "numero") {
          // Filtrer par ID si la catégorie est "numero"
          return courrier.id.toString() === key;
        } else if (categorie === "nom") {
          // Filtrer par nom si la catégorie est "nom"
          return courrier.name.toLowerCase().includes(key.toLowerCase());
        } else if (categorie === "registre") {
          // Filtrer par référence si la catégorie est "reference"
          return courrier.registre.toLowerCase().includes(key.toLowerCase());
        } else {
          // Catégorie non prise en charge
          console.error("Catégorie non prise en charge.");
          return false;
        }
      });
      this.donnees = courriersFiltres;
      this.donneesChange.next(this.donnees);

      // Retourner les courriers filtrés
      return courriersFiltres;

    }else{
      const courriersFiltres = this.courriers.filter(courrier => {


          return courrier.status.toString() === key;

      });

      return courriersFiltres;

    }


  }

  searchDossierByChambre(chambreName: string): string[] {
    const dossiers = this._Dossiersenrolles.filter(dossier => dossier.chambre.name === chambreName);
    const numeros = dossiers.map(dossier => dossier.numero);
    return numeros;
  }

  searchInfraction(infraction: string): string[] {
    const infractions = this.infractions.filter(inf => inf.name === infraction);
    
    return infractions;
  }






  // getBillSubscriptions(pagetId ?: number){
  //   return this.http.get<any>(this.API_URL + 'auth/billSubscriptions/' + pagetId)
  // }

  // // all subscription
  // getAllSubscriptions(pagetId ?: number){

  //   return this.http.get<any>(this.API_URL + 'auth/subscriptions/' + pagetId)
  // }

  // // get currencies
  // getCurrencies(){

  //   return this.http.get<any>(this.API_URL + 'public/currencies/' )
  // }

  // // payment
  // paymentSubs(data? :any){

  //   return this.http.post<any>(this.API_URL + 'credit/send',data)
  // }

}
