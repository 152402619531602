<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>
  
  
  <div class="page">

      <div class="d-flex">
        <h4 class="text-light" >Informations du profile </h4>
     </div>

  
      <!-- tabs -->
      <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">

        <input type="radio" name="pcss3t" checked  id="myprofil"class="myprofil">
        <label for="myprofil">Profile </label>
        <input type="radio" name="pcss3t"   id="profiltab1"class="profil_tab-content-first">
        <label for="profiltab1">Détails </label>
  
        <input type="radio" name="pcss3t"   id="tab2"class="profil_tab-content-2">
  
        <label for="tab2">Informations complementaires</label>
       
  
        <input type="radio" name="pcss3t"   id="tab3"class="profil_tab-content-3">
  
        <label for="tab3">Paramétres</label>
  
  
  
  
        <!--
        <input type="radio" name="pcss3t" id="tab5" class="tab-content-last">
        <label for="tab5"><i class="icon-globe"></i>Newton</label> -->
  
        <ul  style="height: 450px;">

          <li class="tab-content myprofil typography" style="overflow: hidden;">
            <br>
   
   
             <fieldset >
               <div class="form-card"><br>
                   
     
                <div class="container">
                  <div class="cover-photo">
                    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" class="profile">
                  </div>
                  <div class="profile-name text-light">Anne Ibrahim</div>
                  <button class="msg-btn">blackbou@gmail.com</button>&nbsp;&nbsp;
                  <button class="msg-btn">Admininistrateur</button>
                  <!-- <button class="follow-btn">Following</button> -->
                  
                </div>
 
               </div>
             </fieldset>
           </li>
          <li class="tab-content profil_tab-content-first typography" style="overflow: hidden;">
           <br>
  
  
            <fieldset ><br>
              <div class="form-card">
                  
    
                  <div class="row">
                    <div class="col">
                      <label class="fieldlabels">Email: *</label> <input type="email" class="form-control" name="email"  />
                      <label class="fieldlabels">Prenom: *</label> <input type="text" class="form-control" name="prenom"  />
                      <label class="fieldlabels">Langue: *</label> <input type="text" class="form-control" name="langue"  />
                      
                    
                    </div> &nbsp;&nbsp;
                    <div class="col d-flex  flex-column justify-content-between " >
    
                      <label class="fieldlabels">Nom utilisateur: *</label> <input type="text" class="form-control" name="user"  />
                      <label class="fieldlabels">Nom: *</label> <input type="text" class="form-control" name="nom"  />
                      <label class="fieldlabels">Fuseau horaire: *</label> <input type="text" class="form-control" name="fhoraire"  />

                    </div>
                  </div>

              </div>
            </fieldset>
          </li>
  
          <li class="tab-content profil_tab-content-2 typography">
            <fieldset ><br>
              <div class="form-card">
                  
    
                  <div class="row">
                    <div class="col">
                      <label class="fieldlabels">Sexe: </label>
                         <select class="custom-select" id="inputGroupSelect01">
                          <option value="1">Masculin</option>
                          <option value="2">Feminin</option>
                        </select>
                      <label class="fieldlabels">Date naissance: </label> <input type="text" class="form-control" name="dnaiss"  />
                      <label class="fieldlabels">Interets: </label>
                      <textarea style="height: 220px;" class="form-control" value=""></textarea>
                      
                    
                    </div> &nbsp;&nbsp;
                    <div class="col d-flex  flex-column justify-content-between " >
    
                      <label class="fieldlabels">Téléphone: </label> <input type="text" class="form-control" name="tel"  />
                      <label class="fieldlabels">Lieu: </label> <input type="text" class="form-control" name="lieu"  />
                      <label class="fieldlabels">Biographie</label>
                      <textarea style="height: 220px;" class="form-control" value=""></textarea>

                    </div>
                  </div>

              </div>
            </fieldset>
  
          </li>
  
          <li class="tab-content profil_tab-content-3 typography">
            <fieldset ><br>
              <div class="form-card"><br>
                  
                <div class="container">
                  <div class="login-page">
                    <div class="form">
                     
                      <form class="login-form">
                        <input type="text" placeholder="Ancien mot de passe"/>
                        <input type="password" placeholder="Nouveau mot de passe"/>
                        <button>Changer mot de passe</button>
                       
                      </form>
                    </div>
                  </div>
                </div>
                

              </div>
            </fieldset>
  
          </li>
  
          
        </ul>
      </div>
    </div>
  
  
</body>
</html>