
<body>

    <div class="main">
        <app-lang-dropdown class="lang-dropdown"></app-lang-dropdown>
        <div class="container a-container" id="a-container" *ngIf="!signIn">
            <form [formGroup]="loginForm" (ngSubmit)="loginToFrapper( loginForm )" class="form">
                <div class="hit-the-floor">{{"Login" | translate}}</div>
                <input class="form__input" id="login" formControlName="username" type="text"
                    placeholder="{{'Email' | translate}}">
                <input id="pwd" placeholder="{{'Password' | translate}}" formControlName="password" type="password"
                    class="form__input">
                <button type="submit" class="form__button button submit"> {{"Login" | translate}}</button><br><br>
              <span *ngIf="IS_PUBLIC_FRONT==1">
                 <p (click)="switchContent()">Créer un compte? <span><u>Cliquez ici</u> </span></p>
              </span>

            </form>


        </div>
        <div class="register float-left" *ngIf="signIn">
            <div class="form_wrapper">
                <div class="form_container">
                  <div class="title_container">
                    <h2><b>Inscription</b></h2>
                  </div>
                  <div class="row clearfix">
                    <div class="" style="width: 100%;">
                      <form>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                          <input type="text" [formControl]="registerFormControl.full_name" name="full_name" placeholder="Nom complet" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                            <input type="text" [formControl]="registerFormControl.pseudo" name="pseudo" placeholder="Pseudo" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
                            <input type="email" [formControl]="registerFormControl.email" name="email" placeholder="Email" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-mobile"></i></span>
                            <input type="text" [formControl]="registerFormControl.phone_number" name="telephone" placeholder="N° telephone" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                          <input type="password" [formControl]="registerFormControl.password" name="password" placeholder="Mot de passe" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                          <input type="password" [formControl]="registerFormControl.confirm_password"  name="password" placeholder="Confirmer mot de passe" required />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-indent"></i></span>
                            <input type="email" [formControl]="registerFormControl.cni_number" name="email" placeholder="Piece d'identite" required />
                        </div>

                        <input class="button" type="submit" (click)="register()" value="Créer un compte" />
                      </form>
                      <p (click)="switchContent()">J'ai deja un compte! <span><u>Se connecter</u></span></p>
                    </div>
                  </div>
                </div>
              </div>

        </div>

        <div class="switch" id="switch-cnt">
            <!-- <img src="../../../assets/images/Insoft.jpeg" alt=""> -->
            <img  src="../../../../assets/images/ejustice.png" alt="">
            <div class="switch__circle"></div>
            <div class="switch__circle switch__circle--t"></div>
            <div class="switch__container" id="switch-c1"></div>
        </div>
    </div>



    <ngx-ui-loader></ngx-ui-loader>

</body>
