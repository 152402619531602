import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EnrollementService } from '../../services/frapper/enrollement.service';
import { JugementsService } from '../../services/frapper/jugements.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';
import { AlertesService } from '../../services/public/alertes.service';
import { SuccessDialogComponent } from '../../widgets/successDialog/success-dialog.component';
import { AddPieceComponent } from '../add-piece/add-piece.component';
import * as $ from 'jquery';
import {Fiche_a_classerService} from "../../services/frapper/fiche_a_classer.service";
import { VisualizeCasierComponent } from '../visualize-casier/visualize-casier.component';
import { VisualizeExecutionComponent } from '../visualize-execution/visualize-execution.component';

@Component({
  selector: 'app-view-details-fiches',
  templateUrl: './view-details-fiches.component.html',
  styleUrls: ['./view-details-fiches.component.scss']
})
export class ViewDetailsFichesComponent {

  @Input() parentModal: BsModalRef;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data: any;
  type:any;
  infractions: any
  enrolle:any = {
    "type_daffaire": "Pénale",
    "numero_du_jugement": "None",
    "chambre": "Aucune",
    "date_du_jugement": "None"
  };
  jugement:any;
  modalRef!: BsModalRef;
  selectedInfractions: string[] = [];
  Infractions: any[] = [];
  Amendes: any[] = [];
  Emprisonements: any[] = [];
  ContrainteParCorps: any[] = [];
  affiliations: any[] = [];
  Reglements: any[] =[]

  TypeDeCondamnation: any[] = [];
  CategorieDeCondamnation: any[] = [];

  numero_du_jugement=null
  fiche_name=null
  Decision_Judiciaire = null
  Date_decision = null
  Categorie_Decision = null


  ALLOWED_DECISION_FINAL = false
  ALLOWED_SAVE_DECISION = false
  profil

  nom_pere =null
  prenom_pere =null
  nom_mere =null
  prenom_mere =null

  constructor(private modalService: BsModalService,public options: ModalOptions,
    private ngxService: NgxUiLoaderService,
    private alerte:AlertesService,
              private jugementService: JugementsService,
              private fiche_a_classer: Fiche_a_classerService,
    private datePipe: DatePipe,
    private role:EnrollementService,
    private penalService: PenalCourrierService,private enrollementService: EnrollementService) {
    this.data = options.initialState.data

    console.log('Fiche a classer ==> ', this.data)
    console.log(this.profil)
    this.numero_du_jugement = this.data.numero_du_jugement
    this.fiche_name = this.data.name
    this.profil = localStorage.getItem('profil')
  }


  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  ChangeStatus(){
    this.profil = localStorage.getItem('profil')
    console.log(this.profil)
    if(this.profil=='JUGE'){
      this.ALLOWED_DECISION_FINAL =true
      this.ALLOWED_SAVE_DECISION = true
    }

    if(this.profil=='GREFFIER'){
      this.ALLOWED_DECISION_FINAL =true
      this.ALLOWED_SAVE_DECISION = true
    }

    if(this.data.status == "En attente de date du Jugement" ||this.data.status == "Decision rendue" || this.data.status == "En appel" || this.data.status == "Definitive"){
      this.ALLOWED_SAVE_DECISION = false
    }
     if(this.data.status !="Decision rendue"){
       this.ALLOWED_DECISION_FINAL = false
     }

  }

  UpdateDecision(){
    this.ngxService.start()
    let table_tqhi =this.ContrainteParCorps
    let table_emp =this.Emprisonements
    let table_amendes_j =this.Amendes

    for(let lg of this.lignes){
      let to_add ={
        "docstatus": 1,
        "debut": lg.debut,
        "fin": lg.fin,
        "main_leve": lg.main_leve,
        "parent": this.data.name,
        "parentfield": "table_tqhi",
        "parenttype": "Jugements",
        "doctype": "fiche_a_classer_contrainte_par_corps"
      }
      table_tqhi.push(to_add)
    }
    for(let lg of this.lignes_condamenations){
      let to_add ={
        "docstatus": 1,
        "type": lg.type,
        "durée": lg['durée'],
        "périodicité": lg['périodicité'],
        "parent": this.data.name,
        "parentfield": "table_emp",
        "parenttype": "Jugements",
        "doctype": "fiche_a_classer_emprisonement"
      }
      table_emp.push(to_add)
    }
    for(let lg of this.lignes_ammendes){
      let to_add ={
        "docstatus": 1,
        "montant": lg.montant,
        "aux_depens_liquides_a_la_somme": lg.aux_depens_liquides_a_la_somme,
        "parent": this.data.name,
        "parentfield": "table_amendes_j",
        "parenttype": "Jugements",
        "doctype": "fiche_a_classer_amende"
      }
      table_amendes_j.push(to_add)
    }

    let body ={
      "status": "Decision rendue",
      "date_de_decision":this.Date_decision,
      "categorie_de_la_decision":this.Categorie_Decision,
      "decision_judiciaire":this.Decision_Judiciaire,
      table_tqhi,table_emp,table_amendes_j
    }

    this.jugementService.updateJugement(this.data.name,body).then(res =>{
      console.log('REsultat ==> ',res)
      this.openModalSuccessDialog('Sauvegarde effective',1)
      this.jugementService.getOneJugement(this.data.name).then(res =>{
        //console.log(res)
        this.ChangeStatus()
        this.data=res.data
        this.Infractions = res.data.table_infractions
        this.Amendes = res.data.table_amendes_j
        this.Emprisonements = res.data.table_emp
        this.ContrainteParCorps = res.data.table_tqhi
        this.affiliations = res.data.affiliations
        this.ngxService.stop()
      })

    },err=>{
      console.log('Errue ==> ',err)
      this.alerte.errorNotification('Decision','Erreur lors de la mise a jours')
      this.ngxService.stop()
    })

  }

  UpdateFinalStatus(value){
    this.ngxService.start()

    let body ={
      "status": value
    }

    this.jugementService.updateJugement(this.data.name,body).then(res =>{
      console.log('REsultat ==> ',res)
      this.openModalSuccessDialog('Mise a jour effective',1)
      this.jugementService.getOneJugement(this.data.name).then(res =>{
        //console.log(res)
        this.data=res.data
        this.Infractions = res.data.table_infractions
        this.Amendes = res.data.table_amendes_j
        this.Emprisonements = res.data.table_emp
        this.ContrainteParCorps = res.data.table_tqhi
        this.affiliations = res.data.affiliations
        this.ChangeStatus()
        this.ngxService.stop()
      })
    },err=>{
      console.log('Errue ==> ',err)
      this.alerte.errorNotification('Miser ajour','Erreur lors de la mise a jours')
      this.ngxService.stop()
    })

  }

  openModalSuccessDialog(message: string, not_close=0) {
    const config: ModalOptions = {
      initialState: {
        message: message
      }
    };
    setTimeout(() => {
      if (this.modalService) {
        if(not_close==0){
          this.modalService.hide();
        }
      }
    }, 1000);
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(SuccessDialogComponent, Object.assign({}, config, {class:'modal-sm'}));
  }

  async getDetailEnrolleJugement() {
    let filter = '[["numero_du_jugement" , "=","' + this.data.numero_de_jugement + '"]]'
    //this.enrolle = this.penalService._Dossiersenrolles.find(objet => objet.numero === 'AR-01-2024');
    this.enrolle = (await this.role.getAllMyRole(filter)).data[0]
    console.log('llljjj===> ',this.enrolle);
  }

  async getTypeOfCondamnation(){
    this.TypeDeCondamnation = (await this.jugementService.getAllCondamnationType()).data
   // console.log(this.TypeDeCondamnation)
  }
  async getCateggorieOfCondamnation(){
    this.CategorieDeCondamnation = (await this.jugementService.getAllCondamnationCategorie()).data
    // console.log(this.TypeDeCondamnation)
  }

  UpdateCategoriCondoamnatione(value){
    this.Categorie_Decision = value
    //console.log(this.Categorie_Decision)
  }
  UpdateTypeCondamnation(value){
    this.Decision_Judiciaire = value
   // console.log(this.Decision_Judiciaire)
  }
  UpdateDatedeDecision(value){
    this.Date_decision = value
  }

  lignes: any[] = [];
   indexRow = 2;

  ajouterLigne() {
    this.lignes.push({id:this.indexRow++, colonne1: '', colonne2: '' });
  }
  // condamenation
  lignes_condamenations: any[] = [];
   indexRow_cond = 2;

  ajouterLigne_condamnation() {
    this.lignes_condamenations.push({id:this.indexRow_cond++, colonne1: '', colonne2: '' });
  }

  optionsSelect: string[] = ['Ferme', 'Sursis'];
  periodicite: string[] = ['An(s)', 'Mois', 'Jour(s)'];

  // ammendements
  lignes_ammendes: any[] = [];
   indexRow_ammende = 2;

  ajouterLigne_ammendements() {
    this.lignes_ammendes.push({id:this.indexRow_ammende++, colonne1: '', colonne2: '' });

  }

  deleteInfraction(infraction: any){
    this.selectedInfractions = this.selectedInfractions.filter(item => item != infraction);


  }

  deleteContrainte(element: any){
    this.lignes = this.lignes.filter(item => item != element);
  }

  deleteEmprisonnement(element: any){
    this.lignes_condamenations = this.lignes_condamenations.filter(item => item != element);
  }

  deleteAmmendes(element: any){
    this.lignes_ammendes = this.lignes_ammendes.filter(item => item != element);
  }


  async getAllInfrations() {
    this.ngxService.start();
    try {
      this.infractions = (await this.enrollementService.getAllInfractions()).data
      this.ngxService.stop();

    } catch (error) {
      this.ngxService.stop();
    }

  }

  toggleSelection(infraction: string): void {
    if (this.infractions.includes(infraction)) {
      this.selectedInfractions = this.selectedInfractions.filter(item => item !== infraction);
    } else {
      this.selectedInfractions.push(infraction);
    }
  }

  openModalAddPiece() {

    const config: ModalOptions = {
      initialState: {
        // message: message
      }
    };
    // this.penalService.hiddeModal(this.parentModal);
    this.modalRef = this.modalService.show(AddPieceComponent, Object.assign({}, config, {class:'modal-lg'}));
  }

  hidde() {
    if (this.modalService) {
      this.modalService.hide();
    }
  }

  initializeStepper() {
    console.log('innnnn ssteppp 31  '+this.type );
    var stepFirst = this.type;

    $(document).ready(function(){
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      var current = 1;
      var steps = $("fieldset").length;


      setProgressBar(current);



      if (stepFirst == 'step3') {
        console.log('in step333 === '+ stepFirst);


        $("fieldset.step1").hide();
        $("fieldset#step1").addClass("active");
        $("fieldset.step2").hide();
        $("#step2").addClass("active");
          // Afficher le fieldset de classe step3
          $("fieldset.step3").show();
        // $("fieldset").eq(2).show();
        current = 4;
        setProgressBar(current++);

        // Ajouter la classe "active" au deuxième élément de la barre de progression
        $("#progressbar li").eq(2).addClass("active");
        console.log("Au moins un élément fieldset a la classe 'cache'");
      }




      // Fonction pour définir la barre de progression
      function setProgressBar(curStep){
          var percent = (100 / steps) * curStep;
          percent = percent
          $(".progress-bar").css("width",percent+"%");
      }

      // Logique pour passer à l'étape suivante
      $(".next").click(function(){
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          // Ajouter la classe "active" à l'étape suivante de la barre de progression
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          // Afficher le fieldset suivant avec une animation
          next_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(++current);
      });

      // Logique pour revenir à l'étape précédente
      $(".previous").click(function(){
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();

          // Retirer la classe "active" de l'étape actuelle de la barre de progression
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

          // Afficher le fieldset précédent avec une animation
          previous_fs.show();
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  opacity = 1 - now;
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              },
              duration: 500
          });
          setProgressBar(--current);
      });
  });

  }

  doAffiliation(){
    for(let aff of this.affiliations){
      if(aff.lien =='Pere'){
        this.nom_pere = aff.nom
        this.prenom_pere = aff.prenom
      }
      if(aff.lien =='Mere'){
        this.nom_mere = aff.nom
        this.prenom_mere = aff.prenom
      }
    }
  }
  async getDetailsFiche(fiche_name:any) {
    this.ngxService.start()
    try {
      const res = await this.fiche_a_classer.getOneFiche(fiche_name);
      //console.log(res)
      //this.ChangeStatus()
      this.data = res.data;
      this.Infractions = res.data.infractions;
      this.Amendes = res.data.amende;
      this.Emprisonements = res.data.emprisonnement;
      this.ContrainteParCorps = res.data.contraintes_par_corps;
      this.affiliations = res.data.affiliations;
      this.Reglements = res.data.reglement;
      this.doAffiliation();

      this.Categorie_Decision = res.data.categorie_de_la_decision;
      this.Decision_Judiciaire = res.data.decision_judiciaire;
      this.ngxService.stop()
    } catch (error) {
      // Handle errors here
      this.ngxService.stop()
    }
  }


   async ngOnInit() {
    var self = this;
    let fiche_name = this.fiche_name ==null ? this.data.name: this.fiche_name
    await this.getDetailsFiche(fiche_name)



    $(document).ready(function(){
      console.log('dddddd rrreeaady');

      self.initializeStepper();

    // table_infractions

    const selectBtn = document.querySelector(".select-btn"),
    items = document.querySelectorAll(".item"),
    selectedItemsTable = document.getElementById("selectedItemsTable")
    const tableBody = document.querySelector(".details_infractionTable tbody") as HTMLTableSectionElement;

  selectBtn.addEventListener("click", () => {
    selectBtn.classList.toggle("open");
  });

  items.forEach(item => {
    item.addEventListener("click", () => {
        item.classList.toggle("checked");

        const itemText = item.querySelector(".item-text").textContent;

        if (item.classList.contains("checked")) {
            const newRow = tableBody.insertRow();
            const cell1 = newRow.insertCell(0);
            const cell2 = newRow.insertCell(1);
              cell1.textContent = itemText;
              cell2.innerHTML = '<i class="fas fa-eye m-t-3"></i>';
            // cell2.textContent = "Some data"; // Vous pouvez remplir cette colonne avec des données supplémentaires si nécessaire
        } else {
            // Supprimez la ligne correspondant à l'élément désélectionné
            const rows = tableBody.getElementsByTagName("tr");
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].cells[0].textContent === itemText) {
                    tableBody.deleteRow(i);
                    break;
                }
            }
        }
    });
  });

  // table_contraintes




  })

  }
  openModalVisualCasier(data: any) {
    console.log({'datavisualize': data});


    const config: ModalOptions = {
      backdrop: true,
      initialState: {
        data: data,

        parentModal : null
      }
    }


     // Ouvrir le modal   ViewDetailsCourrierComponent
     const modalRef = this.modalService.show(VisualizeExecutionComponent,Object.assign({}, config, { class: 'modal-lg' }));

     // Mettre à jour l'option initialState avec le modalRef
     modalRef.content.parentModal = modalRef;
  }
}
