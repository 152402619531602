<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <img class="logoimg" (click)="navigateHome()" src="../../../../assets/images/ejustice.png" alt="">
    <a class="logo" href="#" ></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <!-- <nb-actions size="small">

      <nb-action>
        <app-lang-dropdown></app-lang-dropdown>
      </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="my-context-menu" [onlyPicture]="userPictureOnly" [name]="user?.username" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions> -->




  <span style="font-size: 15px;color: #102770;margin-right: 10px;">{{profil}}</span>

  <div class="sec-center">  
    <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
    <label class="for-dropdown d-flex " for="dropdown"><i class="fas fa-regular fa-user" style="margin-right: 10px;"></i> <i class="fas fa-solid fa-caret-down" ></i></label>
    
    <div class="section-dropdown d-flex flex-column text-nowrap"> 
      <div class="d-flex justify-content-between" (click)="openModalProfile()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile"  />
        <span style="font-size: 10px; cursor: pointer;"  class="text-light"><i class="fas fa-regular fa-user" style="font-size: 10px; left: 0; "  ></i>  {{profil}}</span>
      </div>
      <div class="d-flex justify-content-between" (click)="logout()" >
        <input class="dropdown-sub" type="checkbox" id="dropdown-profile" name="dropdown-profile" />
        <span style="font-size: 11px; cursor: pointer;"  class="text-light"><i class="fas fa-solid fa-lock" style="font-size: 10px; cursor: pointer;"  ></i> Se déconnecter </span>
      </div>
      
    
      

      
    
  </div>
</div>