<div class="center">
  <h4 class="text-light m-auto text-center" >Ajout piéce</h4><br>
  <form #addPiece="ngForm" (submit)="createPiece(addPiece)">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <div class="inputbox">
          <input type="text"  name="file_name" ngModel required>
          <span>Nom de la piéce </span>
        </div>

        <div class="inputbox">
          <input type="text"  name="numero_courrier" ngModel required>
          <span>Numéro courrier </span>
        </div>
      </div>&nbsp;&nbsp;

      <div class="zone">

        <div id="dropZ">
          <i class="fa fa-cloud-upload"></i>
          <div>Deposer ici </div>
          <span>OU</span>
          <div class="selectFile">
           
            <label class="file-label" for="file"> Importer </label>
            <input type="file" class="file-input"  id="file" (change)="onFileSelected($event)" accept="image/*">
          </div>
          <!-- <p>File size limit : 10 MB</p> -->
        </div>
  
      </div>
      
    </div>


    






    <!-- <div class="file-upload">
      <button class="file-upload-btn" type="button" (click)="triggerFileInput()">Add Image</button>

      <div class="image-upload-wrap">
        <input class="file-upload-input" type="file" (change)="readURL($event.target)" accept="image/*" />
        <div class="drag-text">
          <h3>Drag and drop a file or select add Image</h3>
        </div>
      </div>
      <div class="file-upload-content">
        <img class="file-upload-image" [src]="imageSource" alt="your image" />
        <div class="image-title-wrap">
          <button type="button" (click)="removeUpload()" class="remove-image">Remove <span class="image-title">{{imageName}}</span></button>
        </div>
      </div>
    </div> -->


    <div class="" style="margin: auto; text-align: center;">
     <br> <button class="btn bg-success text-light" style="margin: auto; text-align: center;">Enregistrer</button>
    </div>
  </form>
</div>
<script>
  $(document).bind('dragover', function (e) {
    var dropZone = $('.zone'),
        timeout = window.dropZoneTimeout;
    if (!timeout) {
        dropZone.addClass('in');
    } else {
        clearTimeout(timeout);
    }
    var found = false,
        node = e.target;
    do {
        if (node === dropZone[0]) {
            found = true;
            break;
        }
        node = node.parentNode;
    } while (node != null);
    if (found) {
        dropZone.addClass('hover');
    } else {
        dropZone.removeClass('hover');
    }
    window.dropZoneTimeout = setTimeout(function () {
        window.dropZoneTimeout = null;
        dropZone.removeClass('in hover');
    }, 100);
});
</script>
