import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  SOIT_TRANSMIS_DOCTYPE='soit transmis'
  DESIGNATION_DOCTYPE='st_designation'
  ACTIONS_DOCTYPE='st actions'
  constructor(private http: HttpClient, private frapper: FrapperService) {}



  async createSoitTransmisObject(data){
    const result = await this.frapper.CreateDoctype(this.SOIT_TRANSMIS_DOCTYPE,data).toPromise()
    return result
  }

  async getSoitTransmisObject(filter=null){
    const result = await this.frapper.GetDoctypes(this.SOIT_TRANSMIS_DOCTYPE,'["*"]',filter).toPromise()
    return result
  }

  async getDesignations(filter=null){
    let designations = await this.frapper.GetDoctypes(this.DESIGNATION_DOCTYPE,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return designations
  }
  async getDesignationsDetails(name){
    let designation = await this.frapper.GetDoctype(this.DESIGNATION_DOCTYPE,name,'["*"]').toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return designation
  }
  async getActions(filter=null){
    let actions = await this.frapper.GetDoctypes(this.ACTIONS_DOCTYPE,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return actions
  }
  async getActionsDetails(name){
    let action = await this.frapper.GetDoctype(this.ACTIONS_DOCTYPE,name,'["*"]').toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return action
  }

  async getUserByRole(role) {
    let body = {
      "role_name": role
    }
    let doc_assigned_user = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_all_user_with_Role',body).toPromise()
    return doc_assigned_user
  }
  async getIdentificationType(filter=null){
    let id_type = await this.frapper.GetDoctypes('type identifications','["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return id_type
  }
  async createFiles(data){
    const  result = await this.frapper.UploadFile(data).toPromise()
    console.log(result)
    return result
  }
  async updateTheDoctype(doctype_name,doc_name,data_to_upload){
    const  result = await this.frapper.UpdateDoctype(doctype_name,doc_name,data_to_upload).toPromise()
    //console.log(result)
    return result
  }

  async getAllInformationsOfChambre(chambre_name) {
    let body = {
      "chambre_name": chambre_name
    }
    let doc_chambre_datas = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_all_information_of_chambre',body).toPromise()
    return doc_chambre_datas
  }
  async SendEmailNotification(subject,to,body_) {
    let body = {
      "subject": subject,
      "to": to,
      "body": body_
    }
    let send_email = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.rendez_vous.rendez_vous.send_email_notification',body).toPromise()
    return send_email
  }

}
