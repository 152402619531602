import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";

@Injectable({
  providedIn: 'root'
})
export class DossierService {

  Doctype_dossier ='Dossier'
  Doctype_requisitoire_ordonances ='RequisitoiresOrdonances'

  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService) {}

 // Dossier
  async getAllMyDossiers(filter =null) {
    let my_dossier = await this.frapper.GetDoctypes(this.Doctype_dossier,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_dossier
  }

  async getOneDossier(dossierName) {
    let my_dossier = await this.frapper.GetDoctype(this.Doctype_dossier,dossierName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_dossier
  }

  async nouveauDossier(body) {
    let new_dossier = await this.frapper.CreateDoctype(this.Doctype_dossier,body).toPromise()
    return new_dossier
  }

  async updateSatate(docname,new_state){
    let body= {
        "workflow_state": new_state
      }
    let result = this.frapper.UpdateDoctype(this.Doctype_dossier,docname,body).toPromise()
    return result
  }
  async updateDossier(docname,body){
    let result = this.frapper.UpdateDoctype(this.Doctype_dossier,docname,body).toPromise()
    return result
  }

  async getnumeroDossier() {
    let me = localStorage.getItem('me')
    const body= {utilisateur:JSON.parse(me).name}
    console.log('body ==> ',body)
    let courrier = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_my_last_courrier',body).toPromise()
    return courrier
  }

  async assignCourrierToSubstitut(body){
    let assignation = await this.assigneService.doAssignation(this.Doctype_dossier,body.name,body.user)
    return assignation
  }

  async createRequisitoireOrOrdonance(body){
    let new_reqOrd = await this.frapper.CreateDoctype(this.Doctype_requisitoire_ordonances,body).toPromise()
    return new_reqOrd
  }

  async getAllMyRequisitoireOrOrdonance(filter =null) {
    let my_dossier = await this.frapper.GetDoctypes(this.Doctype_requisitoire_ordonances,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_dossier
  }


 // ** Dossier
}
