import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPrintElementService, Config } from 'ngx-print-element';
import { MyCONST } from '../../CONST/myConst';
import { UsercourrierService } from '../../services/frapper/usercourrier.service';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';

import { NgxPrintDirective } from 'ngx-print';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-visualize-execution',
  templateUrl: './visualize-execution.component.html',
  styleUrls: ['./visualize-execution.component.scss'],
})
export class VisualizeExecutionComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  dataAllInformation:any;
  dataPiecesJointes:any  =[];
  type: any;
  courriers: any[] = [];
  internesCourriers: any[] = [];
  internesPrincipals: any[] = [];
  isButtonDisabled: boolean = true;
  verification : String  = MyCONST.DOMAIN
  nom_pere =null
  prenom_pere =null
  nom_mere =null
  prenom_mere =null

  tribunal: string = MyCONST.DETAIL_TRIBUNAL;

  profil: string ;
  @Input() parentModal: BsModalRef;

  @ViewChild('elementToPrint') printElement!:any;


  @ViewChild(NgxPrintDirective) ngxPrint!: NgxPrintDirective;


  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,
              private penalCourrierService: PenalCourrierService,
              private userCourrierService: UsercourrierService,
              public print: NgxPrintElementService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {
    this.data = this.options.initialState.data;
    this.type = this.options.initialState.type;
    console.log({'data in visual': this.data});
    this.doAffiliation()
    

  }

  doAffiliation(){
    for(let aff of this.data.affiliations){
      if(aff.lien =='Pere'){
        this.nom_pere = aff.nom
        this.prenom_pere = aff.prenom
      }
      if(aff.lien =='Mere'){
        this.nom_mere = aff.nom
        this.prenom_mere = aff.prenom
      }
    }
  }

  getFormattedDate(date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }




  public config: Config = {
    printMode: 'template', // template-popup
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'casier',
    // htmlType: 'text',
    stylesheets: [{ rel: 'stylesheet', href: '../../../assets/style/css/bootstrap.min.css' }],
    
    styles: [
      `
      
      @media print {
        @page {
          size: A4; /* Définit la taille de la page comme A4 */
          margin: 0; /* Supprime les marges par défaut */
        }
        
        body {
          width: 98%; /* Largeur de la page A4 en millimètres */
          margin-left: 20px;
          margin-right: 20px;
          .mfooter{
            margin-bottom: 100px;
          }
          
          
          p{
            background-color: red !important;
          }
          



          .second,.third,.for, .five{
            height: 1000px;
            
            margin-bottom: 1000px;
            margin-auto;
            font-size: 30px;
          }

          .header{
            display: flex;
            justify-content: space-between;
            width:100%;
            
           
          }
          .container {
            display: flex; /* Utilisation de flexbox pour aligner les éléments horizontalement */
            align-items: center; /* Centre verticalement les éléments */
            flex-direction: row;
          }
          .block{
            display: flex;
            justify-content: space-between;
            width:100%;
          
           
           
    
            .trib{
              font-size: 25px;
              min-width: 320px;
              margin-top: 10px;
              margin-left: 230px; /* Supprime les marges par défaut */
              padding-top: -20px;
              height:20px;


             }
             
             .vertical-text {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
           
              text-align: center;
              margin-top: -150px;
              
             
             margin-left: 0px;
    
            }
    
            .vertical-text-renseigne {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
              text-align: center;
              margin-top: -260px;
              
             
              margin-left: 0px;
          
    
            }
    
            .vertical-text-condamn {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
              text-align: center;
              margin-top: -270px;
              
             
             margin-left: 273px;
            }

            .vertical-text-jugement {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
              text-align: center;
              margin-top: -200px;
              
             
             margin-left: 0px;
            }

            .vertical-text-infrac {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
              text-align: center;
              margin-top: -220px;
              
             
             margin-left: 273px;
            }

            .vertical-text-second {
              writing-mode: vertical-rl; 
              transform: rotate(-180deg); 
              transform-origin: bottom; 
              white-space: nowrap;
              font-size: 20px;
              text-align: center;
              margin-top: -750px;
              width: auto;
              height: auto;
              
             
            
              color: rgb(130, 130, 194);
            
            }
    
            
            .vl {
              border-left: 6px solid black; /* Style de la ligne verticale */
              height: 300px; /* Hauteur de la ligne verticale */
              margin-top: 12px;
            
              
            }
    
            .vl-renseigne {
              border-left: 6px solid black; /* Style de la ligne verticale */
              height: 250px; /* Hauteur de la ligne verticale */
              margin-top: 12px;
            
              
            }
    
            .vl-condamn {
              border-left: 6px solid black; /* Style de la ligne verticale */
              height: 220px; /* Hauteur de la ligne verticale */
              margin-top: 10px;
            }

            .vl-jugement {
              border-left: 6px solid black; /* Style de la ligne verticale */
              height: 180px; /* Hauteur de la ligne verticale */
              margin-top: 10px;
            }

            .vl-infrac {
              border-left: 6px solid black; /* Style de la ligne verticale */
              height: 130px; /* Hauteur de la ligne verticale */
              margin-top: 2px;
            
            }
            
    
            .table_ecivil{
              
              margin-left: -900px;
              border:none;
              min-width:500px;
              border-collapse: collapse;
              border:none;
              margin-top: -10px;
             

              th, td {
               
                font-size: 25px;
              padding-top: 2px;

              
                border: none; /* Supprime toutes les bordures des cellules */
              }
            }
    
            .table_condamn{
              width:100%;
              min-width:500px;
              
              margin-left: -630px;
              border-collapse: collapse;
              border-spacing: 0;
              margin-top: -10px;
              border:none;
              th, td {
               
                font-size: 25px;
                padding-top: 2px;
              
                border: none; /* Supprime toutes les bordures des cellules */
              }
            }

            .table_jugement{
              margin-left: -900px;
              border:none;
              min-width:500px;
              border-collapse: collapse;
              border:none;
              margin-top: -10px;
             

              th, td {
               
                font-size: 25px;
              padding-top: 2px;

              
                border: none; /* Supprime toutes les bordures des cellules */
              }
            }

            .table_second{
              width:100%;
              min-width:500px;
              border-spacing: 0;
              
              margin-left: -800px;
              border-collapse: collapse;
              border:none;
              th, td {

                border: none; /* Supprime toutes les bordures des cellules */
              }
            }

            .table_infrac{
              width:100%;
              min-width:500px;
              border-spacing: 0;
              
              margin-left: -630px;
              border-collapse: collapse;
              border:none;
              margin-top: -30px;
              th, td {
               
                font-size: 25px;
                padding-top: 2px;

              
                border: none; /* Supprime toutes les bordures des cellules */
              }
            }


            
          }
        }
      }
      
  
      
      
      
      `
      
    ]
  }

  



  activePane = 0;

  

  onPrint2(el: any) {
    try {
      this.print.print(el, this.config).subscribe(console.log);

    } catch (error) {
      console.log('dddddd==> '+ error);
      
      
    }
    
  }
}
