export class MyCONST {

    //BACKEND

    static API_URL = 'https://justice.insoftsas.com'
    static DOMAIN = 'http://e-demo.services.justice.sn/'
    static VERIFICATION_URI ='E-justice/PUBLIC/document-verification/'

   /* static APP_NAME = 'E-JUSTICE'
    static TRIBUNAL = 'TGI Guediawaye'
    static DETAIL_TRIBUNAL = 'Tribunal de Grande Instance<br>de Pikine-Guediawaye';*/

    //For RDV
    static APP_NAME = 'Gestion des audiences'
    static TRIBUNAL = 'Ministre de la Justice, Garde des Sceaux'
    static DETAIL_TRIBUNAL = 'Ministre de la Justice, Garde des Sceaux';

    //**For RDV

    static IS_PUBLIC = 0 // ou 1
    static API_PUBLIC = 'http://services.justice.sn:2023'
    static active_chacha = 1
    static key_word = 'IbrahimaBaAnneDitBlackBou'
    static key_size = 32
    static nonce_size = 24
    static createDossier = {ref: 'SEV-1693995863828', label: 'Creation d\'un dossier', type: 'D'}

}
