import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {FrapperService} from "./frapper.service";
import {AssignedService} from "./assigned.service";

@Injectable({
  providedIn: 'root'
})
export class UsercourrierService {

  Docatype_courrier_principal ='Courrier'

  Docatype_classification ='Type de Chaine'
  Docatype_nature_courrier ='Nature de courrier'


  Docatype_courrier_interne ='Courrier Interne'

  constructor(private http: HttpClient, private frapper: FrapperService, private assigneService:AssignedService) {}

 // Courrier Principale
  async getAllMyCourrierPrincipal(filter=null) {
    let my_courriers = await this.frapper.GetDoctypes(this.Docatype_courrier_principal,'["*"]',filter).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_courriers
  }

  async getAllMyCourrierInterne() {
    let my_courriers = await this.frapper.GetDoctypes(this.Docatype_courrier_interne,'["*"]',null).toPromise()
    //console.log('My Courriers Principale ===> ', my_courriers)
    return my_courriers
  }

  async getOneCourrierPrincipal(courrierName) {
    let my_courrier = await this.frapper.GetDoctype(this.Docatype_courrier_principal,courrierName,'["*"]',null).toPromise()
    //console.log('Information Of The Courriers ===> ', my_courrier)
    return my_courrier
  }

  async getAllowedClassification() {
    let classifications = await this.frapper.GetDoctypes(this.Docatype_classification,'["*"]',null).toPromise()
    //console.log('My classifications ===> ', classifications)
    return classifications
  }

  async getAllowedNatureCourrier(filter=null) {
    let nature = await this.frapper.GetDoctypes(this.Docatype_nature_courrier,'["*"]',filter).toPromise()
    //console.log('My classifications ===> ', classifications)
    return nature
  }

  async nouveauCourrier(body) {
    let courrier = await this.frapper.CreateDoctype(this.Docatype_courrier_principal,body).toPromise()
    return courrier
  }
  async nouveauCourrierInterne(body) {
    let courrier = await this.frapper.CreateDoctype(this.Docatype_courrier_interne,body).toPromise()
    return courrier
  }

  async getnumeroCourrier(type_de_courrier=null) {
    let me = localStorage.getItem('me')
    const body= {utilisateur:JSON.parse(me).name,registre:type_de_courrier}
    console.log('body ==> ',body)
    let courrier = await this.frapper.CallCustomAPI('casier_judiciaire.casier_judiciaire.doctype.courrier.courrier.get_my_last_courrier',body).toPromise()
    return courrier
  }

  async assignCourrierToSubstitut(body){
    let assignation = await this.assigneService.doAssignation(this.Docatype_courrier_principal,body.name,body.user)
    return assignation
  }

  async updateSatate(docname,new_state){
    let body= {
      "workflow_state": new_state
    }
    let result = this.frapper.UpdateDoctype(this.Docatype_courrier_principal,docname,body).toPromise()
    return result
  }
  async updateCourrier(docname,body){
    let result = this.frapper.UpdateDoctype(this.Docatype_courrier_principal,docname,body).toPromise()
    return result
  }

 // ** Courrier Principale
}
