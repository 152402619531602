<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0">
  <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css" />
  </head>

  <body>
    <!-- <div class="sticky-container">
      <app-sticky-note></app-sticky-note>
    </div> -->
    <div class="page view-details-courrier">
      <h1 style="font-size: 23px;margin-top: -50px;">Informations du rendez-vous </h1>

      <!-- tabs -->
       <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
         <!-- <input type="radio" name="pcss3t" checked  id="tab1"class="tab-content-first">
         <label for="tab1">Elements du dossier</label>

         <input type="radio" name="pcss3t"   id="tab2"class="tab-content-2">

         <label for="tab2">identification parties prenantes</label> -->

         <input type="radio" name="pcss3t" checked   id="tab1"class="tab-content-first">

         <label for="tab1">Détails</label>



         <ul  style="height: 450px;">
           <li class="tab-content tab-content-first typography" style="overflow: auto;">
            <br>
             <form id="msform"  #form="ngForm" (ngSubmit)="onSubmit(form)">

               <fieldset class="step1">
                 <div class="form-card">

                   <div class="row">
                     <label class="fieldlabels">Date: </label>
                     <input class="form-control" type="date" name="date_rv" [ngModel]="data.date" ngModel />
                   </div>

                   <div class="row">
                     <div class="col">

                       <label class="fieldlabels">Heure début: </label> <input class="form-control" type="time" name="h_debut" [ngModel]="data.heure_debut" ngModel />
                     </div> &nbsp; &nbsp;
                     <div class="col d-flex  flex-column justify-content-between " >
                       <label class="fieldlabels">Heure fin: </label> <input class="form-control" type="time" name="h_fin" [ngModel]="data.heure_fin" ngModel />
                     </div>

                   </div>
                   <div class="row">
                     <div class="col col-md-5">
                       <label class="fieldlabels">Nom complet du demandeur: </label> <input class="form-control" type="text" [ngModel]="data.nom_demandeur" name="nom_demandeur" ngModel />
                     </div>&nbsp; &nbsp;
                     <div class="col">
                       <div class="d-flex">
                         <div>
                           <label class="fieldlabels">Email: </label> <input class="form-control" type="email" name="email" [ngModel]="data.email" ngModel />
                         </div>&nbsp; &nbsp;
                         <div>
                           <label class="fieldlabels">Téléphone: </label> <input class="form-control" type="text" name="telephone" [ngModel]="data.telephone" ngModel />
                         </div>
                       </div>

                     </div>
                   </div>
                   <div class="row">

                     <label class="fieldlabels">Informations: </label>
                     <textarea style="height: 130px;" class="form-control"  placeholder="Informations du rendez-vous" name="information_rv" [ngModel]="data.information" ngModel></textarea>
                   </div>

                 </div>
                 <input type="submit" *ngIf="activeUpdate" name="next" class=" action-button bg-secondary btn-block text-light" value="Mettre à jour"  />

               </fieldset >

             </form>

           </li>


         </ul>

       </div>


     </div>
     <!-- <ngx-ui-loader></ngx-ui-loader> -->
  </body>
</html>



