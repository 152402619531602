import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PenalCourrierService } from '../../services/penal.courrier/penal.courrier.service';


@Component({
  selector: 'ngx-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any;
  profil: number;
  modalRef!: BsModalRef;
  message: any;

  // constructor(private modalService: BsModalService,private options: ModalOptions) {
  //   this.data = this.options.initialState;
  // }
  constructor(private _formBuilder: FormBuilder,private options: ModalOptions,private penalService: PenalCourrierService,private modalService: BsModalService) {
    this.message = this.options.initialState;
  }

  




  ngOnInit() {
    // this.penalService.hiddeModal();
  
  }
}
