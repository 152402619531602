import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { LoginSigninComponent } from './E-justice/authentification/login-signin/login-signin.component';
// import { LoginSigninComponent } from './authentification/login-signin/login-signin.component';

// export const routes: Routes = [
//   {
//     path: 'pages',
//     loadChildren: () => import('./pages/pages.module')
//       .then(m => m.PagesModule),
//   },
//   {
//     path: 'auth',
//     component: LoginSigninComponent,
//     // component: NbAuthComponent,

//     children: [
//       {
//         path: '',
//         component: LoginSigninComponent,
//       },
//       {
//         path: 'login',
//         component: LoginSigninComponent,
//       },
//       {
//         path: 'register',
//         component: NbRegisterComponent,
//       },
//       {
//         path: 'logout',
//         component: NbLogoutComponent,
//       },
//       {
//         path: 'request-password',
//         component: NbRequestPasswordComponent,
//       },
//       {
//         path: 'reset-password',
//         component: NbResetPasswordComponent,
//       },
//     ],
//   },
//   { path: '', redirectTo: 'auth', pathMatch: 'full'},
//   { path: '**', redirectTo: 'auth'},
// ];

export const routes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./pages/pages.module')
  //     .then(m => m.PagesModule),
  // },
  {
    path: 'E-justice',
    loadChildren: () => import('./E-justice/allpage/allpage.module')
      .then(m => m.AllpageModule),
  },
  
  {
    path: '',
    component: LoginSigninComponent,
    // component: NbAuthComponent,
    children: [
      {
         path: 'auth',
        component: LoginSigninComponent,
      },
      {
        path: 'login',
        component: LoginSigninComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full'},
  { path: '**', redirectTo: 'auth'},
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation:'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
