import { Component, ElementRef, EventEmitter, NgZone, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CustomService } from '../../services/frapper/custom.service';

@Component({
  selector: 'app-add-association-prevenu',
  templateUrl: './add-association-prevenu.component.html',
  styleUrls: ['./add-association-prevenu.component.scss']
})
export class AddAssociationPrevenuComponent {
  @Output() newPrevenuAdded = new EventEmitter<any>();
  identification_type:any =[];
  type: any;
  constructor(
    private zone: NgZone,
    public bsModalRef: BsModalRef,
    public options: ModalOptions,
    private customservice:CustomService,
    ) {
      this.getTypeIdentifs();
      this.type = options.initialState.type
    }

    getTypeIdentifs(){
      this.customservice.getIdentificationType().then(res=>{
        this.identification_type = res.data
        //console.log('identification_type ==> ',this.identification_type)
        //console.log('identification_type ==> ',res)
      })
    }

    nom_complet_du_poursuivis: 'Nom complet';
    addresse_complete_du_poursuivis: 'Adresse complète'
    date_de_naissance_du_prévenu: 'Date de naissance'
    lieu_de_naissance_du_prévenu: 'Lieu de naissance'
    profession: 'Profession'
    nom_complet_du_pere_du_prévenu: 'Nom complet du père'
    nom_complet_de_la_mere_du_prévenu: 'Nom complet de la mère'
    type_identification_du_poursuivis: 'Type d\'identification'
    numéro_didentification_du_poursuivis: 'Numéro d\'identification'


    AddNewCourrier(formData: NgForm){
      this.newPrevenuAdded.emit(formData.value);
      this.bsModalRef.hide(); 
      
    }

  hideModal(){
    this.bsModalRef.hide(); 
  }
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
}
