<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<div class="d-flex  justify-content-end mr-4 pt-3" (click)="onPrint2(printElement)">
  <span class="mt-2">Cliquez pour imprimer:&nbsp; </span>
  <i class="fas fa-solid fa-print text-success" style="font-size: 30px;"></i>
</div><br>
<body  #elementToPrint ngxPrintElement>
<div class="first">
  <section class="header " >
    <p class="mt-1" style="">
      <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
    </p>

    <h6 class="mt-1">FICHE A CLASSER AU CASIER JUDICIAIRE</h6>
    <p class="mt-1" >
      <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
    </p>
  </section>
  <section class="etatcivil">
    <div class="d-flex justify-content-center block" >
      <div class="trib" >
         <p [innerHTML]="tribunal"></p>
      </div>

      <div>
        <div class="container">

          <p class=" vertical-text title">ETAT - CIVIL</p>
          <div class="vl"></div>
        </div>

      </div>
      <div>
        <table class="table col-md-10 col-lg-10 table-borderless content-table table_ecivil " >
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">N° C.N.I.</td>
              <th scope="row">:</th>
              <th scope="row">{{data['numéros_didentification']}}</th>
            </tr>
            <tr>
              <td scope="row">Prenom</td>
              <td scope="row">:</td>
              <th>{{data.prenom}}</th>
            </tr>
            <tr>
              <td scope="row">Nom</td>
              <td scope="row">:</td>
              <th>{{data.nom}}</th>
            </tr>
            <tr>
              <td scope="row">Date de naissance</td>
              <td scope="row">:</td>
              <th>{{getFormattedDate(data.date_de_naissance)  }}</th>
            </tr>
            <tr>
              <td scope="row">Lieu de naissance</td>
              <td scope="row">:</td>
              <th>{{data.lieu_de_naissance}}</th>
            </tr>
            <tr>
              <td scope="row">Fils (Fille) de</td>
              <td scope="row">:</td>
              <th>{{prenom_pere}} {{nom_pere}}</th>
            </tr>
            <tr>
              <td scope="row">Et de</td>
              <td scope="row">:</td>
              <th>{{prenom_mere}} {{nom_mere}}</th>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
  </section>


  <!-- renseignement -->
  <section class="etatcivil">
    <div class="d-flex justify-content-center block" >
      <!-- <div class=" trib" >
         <p >{{tribunal}}</p>
      </div> -->
      <div class="trib" >
        <p >Peine expiré le <br> ..........................</p>
        <p >Amende payée le <br> ..........................</p>
     </div>

      <div>
        <div class="container">

          <p class=" vertical-text-renseigne ">RENSEIGNEMENT</p>
          <div class="vl-renseigne"></div>
        </div>

      </div>
      <div>
        <table class="table col-md-10 col-lg-10 table-borderless content-table table_ecivil">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Domicile</td>
              <td scope="row">:</td>
              <th>{{data.residence}}</th>
            </tr>
            <tr>
              <td scope="row">Profession</td>
              <td scope="row">:</td>
              <th>{{data.profession}}</th>
            </tr>
            <tr>
              <td scope="row">Nationalité</td>
              <td scope="row">:</td>
              <th>{{data['nationalités']}}</th>
            </tr>
            <tr>
              <td scope="row">Situation matrimonial</td>
              <th scope="row">:</th>
              <th>{{data.status_matrimoniale}}</th>
            </tr>

            <tr>
              <td scope="row">Mandat de dépôt</td>
              <td scope="row">:</td>
              <th>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</th>
            </tr>
            <tr>
              <td scope="row">Main levée du</td>
              <th scope="row">:</th>
              <th>{{getFormattedDate(data.contraintes_par_corps[0].main_leve)}}</th>

            </tr>


          </tbody>
        </table>

      </div>
    </div>
  </section>

   <!-- Jugement -->
   <section class="etatcivil">
    <div class="d-flex justify-content-center block" >
      <!-- <div class=" trib" >
         <p >{{tribunal}}</p>
      </div> -->
      <div class="trib">
        <p >Contrainte par corps Du  <br> {{getFormattedDate(data.contraintes_par_corps[0].debut )}}</p>
        <p >Au <br>{{getFormattedDate(data.contraintes_par_corps[0].fin)}}</p>
     </div>


     <div>
      <div class="container">

        <p class=" vertical-text-jugement ">JUGEMENT</p>
        <div class="vl-jugement"></div>
      </div>

    </div>
    <div>
      <table class="table col-md-10 col-lg-10 table-borderless content-table table_jugement">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Date</td>
            <td scope="row">:</td>
            <th>{{getFormattedDate(data.date_du_jugement)}}</th>
          </tr>
          <tr>
            <td scope="row">Réglement </td>
            <td scope="row">:</td>
            <th>{{data.reglement[0].reglement}}</th>
          </tr>
          <tr>
            <td scope="row">Signifié à </td>
            <td scope="row">:</td>
            <th>{{data.reglement.signifie_a}}</th>
          </tr>
          <tr>
            <td scope="row">Le</td>
            <td scope="row">:</td>
            <th>{{getFormattedDate(data.reglement.date) }}</th>
          </tr>


        </tbody>
      </table>

    </div>


    </div>
  </section>

   <!-- Condamnation -->
   <section class="etatcivil">
    <div class="d-flex justify-content-center block" >
      <!-- <div class=" trib" >
         <p >{{tribunal}}</p>
      </div> -->


      <div>
        <div class="container">

          <p class=" vertical-text-condamn ">CONDAMNATION</p>
          <div class="vl-condamn"></div>
        </div>

      </div>
      <div>
        <table class="table col-md-10 col-lg-10 table-borderless content-table table_condamn">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Date</td>
              <td scope="row">:</td>
              <th>{{getFormattedDate(data['date_de_la_décision'])}}</th>
            </tr>
            <tr>
              <td scope="row text-wrap" style="width: 50px;">Emprisonnement </td>
              <td scope="row text-nowrap">:</td>
              <th class="text-wrap" >{{data.decision_judiciaire}}</th>
            </tr>
            <tr>
              <td scope="row">Amende </td>
              <td scope="row">:</td>
              <th>{{data.amende[0].montant}}</th>
            </tr>
            <tr>
              <td scope="row text-wrap" style="width: 50px;">Et aux dépens
                liquidés à la somme</td>
              <td scope="row">:</td>
              <th>{{data.amende[0].aux_depens_liquides_a_la_somme}}</th>
            </tr>



          </tbody>
        </table>

      </div>
    </div>
  </section>


  <!-- Infraction -->
  <section class="etatcivil">
    <div class="d-flex justify-content-center block" >
      <!-- <div class=" trib" >
         <p >{{tribunal}}</p>
      </div> -->


      <div>
        <div class="container">

          <p class=" vertical-text-infrac ">INFRACTION</p>
          <div class="vl-infrac"></div>
        </div>

      </div>
      <div>
        <table class="table col-md-10 col-lg-10 table-borderless content-table table_infrac">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Nature</td>
              <td scope="row text-nowrap">:</td>
              <th class="text-nowrap">{{data.infractions[0].nature}}</th>
            </tr>
            <tr>
              <td scope="row">Date </td>
              <td scope="row">:</td>
              <th>{{data.infractions[0].date }}</th>
            </tr>
            <tr>
              <td scope="row">Textes </td>
              <td scope="row text-nowrap">:</td>
              <th class="text-nowrap"></th>
            </tr>



          </tbody>
        </table>

      </div>
    </div>
    <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
      <p style="text-align: right;">Fait le {{getFormattedDate(data.creation)  }}</p>


      <div style="display: flex; justify-content: space-between;">
          <p>Timbre</p>
          <p>Le Procureur de la République</p>
          <p >Pour extrait conforme Le Greffier en Chef</p>
      </div>
    </div>
  </section>
</div><br><br>

<!-- the second -->

  <br><div class="second" style="min-height: 700px;margin-top: 100px;font-size: 25px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
    </section>
    <section class="etatcivil">
      <div class="d-flex justify-content-center block" >
        <!-- <div class=" trib" >
           <p >{{tribunal}}</p>
        </div> -->


        <div>
          <div class="container">
            <div class=" vertical-text-second " style="height: 900px; background-color: rgb(130, 130, 194);">
              <p >EXEMPLAIRE DESTINE A LA GENDARMERIE POUR EXERCICE</p>
              <p >DE LA CONTRAINTE PAR CORPS</p>
            </div>


          </div>

        </div>
        <div class="table_second" >
          <div class="col" >
            <div>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">N° <b>{{data.numero_du_jugement}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">par jugement <b>Contradictoire </b>en date du <b>{{getFormattedDate(data.date_du_jugement)}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 152%;text-align: left;">rendu en matière Correctionnelle Le nommé <b>{{data.prenom}} {{data.nom}}</b></p>
          <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">né(e) le <b>{{getFormattedDate(data.date_de_naissance)}} </b>à <b>{{data.residence}}</b></p>
          <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">de <b>{{prenom_pere}} {{nom_pere}} </b>et de <b>{{prenom_mere}} {{nom_mere}}</b></p>
          <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">profession <b>{{data.profession}}</b></p>

          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">demeurant <b>{{data.residence}}</b></p>
          <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">Non détenu - Détenu suivant mandat de dépôt du <b>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">a été condamné pour <b>{{data.infractions[0].nature}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">commis le</p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.emprisonnement[0]['durée']}} {{data.emprisonnement[0]['périodicité']}} {{data.emprisonnement[0].type}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.amende[0].montant}} F d&#39;amende</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">et aux frais liquidé à la somme de <b>{{data.amende[0].aux_depens_liquides_a_la_somme}}</b></p>
          <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">solidairement avec les nommés</p>


          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 130%;text-align: left;">avertissement de l&#39;article 712 du C.P.P. donne non donne pour extrait délivré par le Greffier</p>
          <p style="text-indent: 0pt;text-align: left;"></p>
          <div>
            <table style="font-size: 25px;margin: auto; text-align: center;">
                <tr >
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Amendes</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">{{data.amende[0].montant}} F</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Timbre</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Enregistrement</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Autres FRAIS</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr >
                    <td style="width:135pt">
                        <p class="s10" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">TOTAL</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">......</p>
                    </td>
                </tr>
            </table>
        </div>


          <div style="display: flex; justify-content: space-between;margin-top: 10px; margin-left: 15%; text-align: center; ">
              <div>
                  <p style="text-indent: 0pt;text-align: left;">Le paiement de la somme ci-dessus indiquée soit <b>{{data.amende[0].montant}} F</b></p>
                  <p style="text-indent: 0pt;text-align: left;">devra intervenir avant le <b>..........</b></p>

                  <p style="text-indent: 0pt;text-align: left;">à la caisse du préposé du trésor.</p>


                  <div>
                      <p style="text-indent: 0pt;text-align: left;">Payé le . . . . . .</p>
                      <p style="text-indent: 0pt;text-align: left;">selon quitance n° . . . . . .</p>
                  </div>

              </div>

          </div>

          <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
            <p style="text-align: right;">Pour extrait délivré le {{getFormattedDate(data.creation)}}</p>


            <div style="display: flex; justify-content: space-between;">
                <p>OBSERVATIONS</p>
                <p>Le Procureur de la République</p>
                <p >TRESOR</p>
            </div>
          </div>


            </div>

          </div>

        </div>
      </div>
    </section>
  </div>


  <!-- the third -->

  <br><div class="third" style="min-height: 700px;font-size: 25px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
    </section>
    <section class="etatcivil">
      <div class="d-flex justify-content-center block" >
        <!-- <div class=" trib" >
           <p >{{tribunal}}</p>
        </div> -->


        <div>
          <div class="container">
            <div class=" vertical-text-second " style="height: 850px; background-color: rgb(195, 215, 96);">
              <p >EXEMPLAIRE DESTINE AU CONDAMNE LUI SERVIR DE RECU</p>
            </div>


          </div>

        </div>
        <div class="table_second" >
          <div class="col" >
            <div>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">N° <b>{{data.numero_du_jugement}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">par jugement <b>Contradictoire </b>en date du <b>{{getFormattedDate(data.date_du_jugement)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;line-height: 152%;text-align: left;">rendu en matière Correctionnelle Le nommé <b>{{data.prenom}} {{data.nom}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">né(e) le <b>{{getFormattedDate(data.date_de_naissance)}} </b>à <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">de <b>{{prenom_pere}} {{nom_pere}} </b>et de <b>{{prenom_mere}} {{nom_mere}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">profession <b>{{data.profession}}</b></p>
    
              <p style="text-indent: 0pt;text-align: left;"></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">demeurant <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">Non détenu - Détenu suivant mandat de dépôt du <b>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">a été condamné pour <b>{{data.infractions[0].nature}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">commis le</p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.emprisonnement[0]['durée']}} {{data.emprisonnement[0]['périodicité']}} {{data.emprisonnement[0].type}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.amende[0].montant}} F d&#39;amende</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">et aux frais liquidé à la somme de <b>{{data.amende[0].aux_depens_liquides_a_la_somme}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">solidairement avec les nommés</p>


          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 130%;text-align: left;">avertissement de l&#39;article 712 du C.P.P. donne non donne pour extrait délivré par le Greffier</p>
          <p style="text-indent: 0pt;text-align: left;"></p>
          <div>
            <table style="font-size: 25px;margin: auto; text-align: center;">
                <tr >
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Amendes</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">{{data.amende[0].montant}} F</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Timbre</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Enregistrement</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Autres FRAIS</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr >
                    <td style="width:135pt">
                        <p class="s10" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">TOTAL</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">......</p>
                    </td>
                </tr>
            </table>
        </div>


          <div style="display: flex; justify-content: space-between;margin-top: 10px; margin-left: 15%; text-align: center; ">
              <div>
                  <p style="text-indent: 0pt;text-align: left;">Le paiement de la somme ci-dessus indiquée soit <b>{{data.amende[0].montant}} F</b></p>
                  <p style="text-indent: 0pt;text-align: left;">devra intervenir avant le <b>.........</b></p>

                  <p style="text-indent: 0pt;text-align: left;">à la caisse du préposé du trésor.</p>


                  <div>
                      <p style="text-indent: 0pt;text-align: left;">Payé le . . . . . .</p>
                      <p style="text-indent: 0pt;text-align: left;">selon quitance n° . . . . . .</p>
                  </div>

              </div>

          </div>

          <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
            <p style="text-align: right;">Pour extrait délivré le {{getFormattedDate(data.creation)}}</p>


            <div style="display: flex; justify-content: space-between;">
                <p>OBSERVATIONS</p>
                <p>Le Procureur de la République</p>
                <p >TRESOR</p>
            </div>
          </div>


            </div>

          </div>

        </div>
      </div>
    </section>
  </div>

  <!-- the forth -->

  <br><div class="for" style="min-height: 700px;font-size: 25px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p> 
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
    </section>
    <section class="etatcivil">
      <div class="d-flex justify-content-center block" >
        <!-- <div class=" trib" >
           <p >{{tribunal}}</p>
        </div> -->


        <div>
          <div class="container">
            <div class=" vertical-text-second " style="height: 850px; background-color: rgb(212, 111, 29);">
              <p >EXEMPLAIRE DESTINE AU TRESOR PUBLIC</p>
            </div>


          </div>

        </div>
        <div class="table_second" >
          <div class="col" >
            <div>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">N° <b>{{data.numero_du_jugement}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">par jugement <b>Contradictoire </b>en date du <b>{{getFormattedDate(data.date_du_jugement)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;line-height: 152%;text-align: left;">rendu en matière Correctionnelle Le nommé <b>{{data.prenom}} {{data.nom}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">né(e) le <b>{{getFormattedDate(data.date_de_naissance)}} </b>à <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">de <b>{{prenom_pere}} {{nom_pere}} </b>et de <b>{{prenom_mere}} {{nom_mere}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">profession <b>{{data.profession}}</b></p>
    
              <p style="text-indent: 0pt;text-align: left;"></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">demeurant <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">Non détenu - Détenu suivant mandat de dépôt du <b>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">a été condamné pour <b>{{data.infractions[0].nature}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">commis le</p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.emprisonnement[0]['durée']}} {{data.emprisonnement[0]['périodicité']}} {{data.emprisonnement[0].type}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.amende[0].montant}} F d&#39;amende</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">et aux frais liquidé à la somme de <b>{{data.amende[0].aux_depens_liquides_a_la_somme}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">solidairement avec les nommés</p>


          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 130%;text-align: left;">avertissement de l&#39;article 712 du C.P.P. donne non donne pour extrait délivré par le Greffier</p>
          <p style="text-indent: 0pt;text-align: left;"></p>
          <div>
            <table style="font-size: 25px;margin: auto; text-align: center;">
                <tr >
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Amendes</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">{{data.amende[0].montant}} F</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Timbre</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Enregistrement</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Autres FRAIS</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr >
                    <td style="width:135pt">
                        <p class="s10" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">TOTAL</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">......</p>
                    </td>
                </tr>
            </table>
        </div>


          <div style="display: flex; justify-content: space-between;margin-top: 10px; margin-left: 15%; text-align: center; ">
              <div>
                  <p style="text-indent: 0pt;text-align: left;">Le paiement de la somme ci-dessus indiquée soit <b>{{data.amende[0].montant}} F</b></p>
                  <p style="text-indent: 0pt;text-align: left;">devra intervenir avant le <b>........</b></p>

                  <p style="text-indent: 0pt;text-align: left;">à la caisse du préposé du trésor.</p>


                  <div>
                      <p style="text-indent: 0pt;text-align: left;">Payé le . . . . . .</p>
                      <p style="text-indent: 0pt;text-align: left;">selon quitance n° . . . . . .</p>
                  </div>

              </div>

          </div>

          <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
            <p style="text-align: right;">Pour extrait délivré le {{getFormattedDate(data.creation)}}</p>


            <div style="display: flex; justify-content: space-between;">
                <p>OBSERVATIONS</p>
                <p>Le Procureur de la République</p>
                <p >TRESOR</p>
            </div>
          </div>


            </div>

          </div>

        </div>
      </div>
    </section>
  </div>


  <!-- the five -->

  <br><div class="five" style="min-height: 700px;font-size: 25px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
    </section>
    <section class="etatcivil">
      <div class="d-flex justify-content-center block" >
        <!-- <div class=" trib" >
           <p >{{tribunal}}</p>
        </div> -->


        <div>
          <div class="container">
            <div class=" vertical-text-second " style="height: 850px; background-color: rgb(67, 164, 233);">
              <p >EXEMPLAIRE DESTINE AU PARQUET</p>
            </div>


          </div>

        </div>
        <div class="table_second" >
          <div class="col" >
            <div>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">N° <b>{{data.numero_du_jugement}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">par jugement <b>Contradictoire </b>en date du <b>{{getFormattedDate(data.date_du_jugement)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;line-height: 152%;text-align: left;">rendu en matière Correctionnelle Le nommé <b>{{data.prenom}} {{data.nom}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">né(e) le <b>{{getFormattedDate(data.date_de_naissance)}} </b>à <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">de <b>{{prenom_pere}} {{nom_pere}} </b>et de <b>{{prenom_mere}} {{nom_mere}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">profession <b>{{data.profession}}</b></p>
    
              <p style="text-indent: 0pt;text-align: left;"></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">demeurant <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">Non détenu - Détenu suivant mandat de dépôt du <b>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">a été condamné pour <b>{{data.infractions[0].nature}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">commis le</p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.emprisonnement[0]['durée']}} {{data.emprisonnement[0]['périodicité']}} {{data.emprisonnement[0].type}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.amende[0].montant}} F d&#39;amende</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">et aux frais liquidé à la somme de <b>{{data.amende[0].aux_depens_liquides_a_la_somme}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">solidairement avec les nommés</p>


          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 130%;text-align: left;">avertissement de l&#39;article 712 du C.P.P. donne non donne pour extrait délivré par le Greffier</p>
          <p style="text-indent: 0pt;text-align: left;"></p>
          <div>
            <table style="font-size: 25px;margin: auto; text-align: center;">
                <tr >
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Amendes</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">{{data.amende[0].montant}} F</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Timbre</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Enregistrement</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Autres FRAIS</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr >
                    <td style="width:135pt">
                        <p class="s10" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">TOTAL</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">......</p>
                    </td>
                </tr>
            </table>
        </div>


          <div style="display: flex; justify-content: space-between;margin-top: 10px; margin-left: 15%; text-align: center; ">
              <div>
                  <p style="text-indent: 0pt;text-align: left;">Le paiement de la somme ci-dessus indiquée soit <b>{{data.amende[0].montant}} F</b></p>
                  <p style="text-indent: 0pt;text-align: left;">devra intervenir avant le <b>............</b></p>

                  <p style="text-indent: 0pt;text-align: left;">à la caisse du préposé du trésor.</p>


                  <div>
                      <p style="text-indent: 0pt;text-align: left;">Payé le . . . . . .</p>
                      <p style="text-indent: 0pt;text-align: left;">selon quitance n° . . . . . .</p>
                  </div>

              </div>

          </div>

          <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
            <p style="text-align: right;">Pour extrait délivré le {{getFormattedDate(data.creation)}}</p>


            <div style="display: flex; justify-content: space-between;">
                <p>OBSERVATIONS</p>
                <p>Le Procureur de la République</p>
                <p >TRESOR</p>
            </div>
          </div>


            </div>

          </div>

        </div>
      </div>
    </section>
  </div>

  <br><div class="five" style="min-height: 700px;font-size: 25px;">
    <section class="header "  >
      <p class="mt-1" style="">
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
      <p>République du Sénégal - Ministére de la justice <br> <span [innerHTML]="tribunal"></span></p>

      <!-- <h6 class="mt-3">FICHE A CLASSER AU CASIER JUDICIAIRE</h6> -->
      <p class="mt-1" >
        <img style="width: 80px" src="../../../../assets/images/ejustice.png" alt="Description de l'image">
      </p>
    </section>
    <section class="etatcivil">
      <div class="d-flex justify-content-center block" >
        <!-- <div class=" trib" >
           <p >{{tribunal}}</p>
        </div> -->


        <div>
          <div class="container">
            <div class=" vertical-text-second " style="height: 850px; ">
              <p >EXEMPLAIRE DESTINE AU GREFFE</p>
            </div>


          </div>

        </div>
        <div class="table_second" >
          <div class="col" >
            <div>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">N° <b>{{data.numero_du_jugement}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">par jugement <b>Contradictoire </b>en date du <b>{{getFormattedDate(data.date_du_jugement)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;line-height: 152%;text-align: left;">rendu en matière Correctionnelle Le nommé <b>{{data.prenom}} {{data.nom}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">né(e) le <b>{{getFormattedDate(data.date_de_naissance)}} </b>à <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">de <b>{{prenom_pere}} {{nom_pere}} </b>et de <b>{{prenom_mere}} {{nom_mere}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">profession <b>{{data.profession}}</b></p>
    
              <p style="text-indent: 0pt;text-align: left;"></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">demeurant <b>{{data.residence}}</b></p>
              <p style="padding-left: 99pt;text-indent: 0pt;text-align: left;">Non détenu - Détenu suivant mandat de dépôt du <b>{{getFormattedDate(data.contraintes_par_corps[0].debut)}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">a été condamné pour <b>{{data.infractions[0].nature}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">commis le</p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.emprisonnement[0]['durée']}} {{data.emprisonnement[0]['périodicité']}} {{data.emprisonnement[0].type}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">à <b>{{data.amende[0].montant}} F d&#39;amende</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">et aux frais liquidé à la somme de <b>{{data.amende[0].aux_depens_liquides_a_la_somme}}</b></p>
              <p style="padding-left: 69pt;text-indent: 0pt;text-align: left;">solidairement avec les nommés</p>


          <p style="text-indent: 0pt;text-align: left;"></p>
          <p style="padding-left: 69pt;text-indent: 0pt;line-height: 130%;text-align: left;">avertissement de l&#39;article 712 du C.P.P. donne non donne pour extrait délivré par le Greffier</p>
          <p style="text-indent: 0pt;text-align: left;"></p>
          <div>
            <table style="font-size: 25px;margin: auto; text-align: center;">
                <tr >
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Amendes</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">{{data.amende[0].montant}} F</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Timbre</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Enregistrement</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr>
                    <td style="width:135pt">
                        <p class="s9" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Autres FRAIS</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">....</p>
                    </td>
                </tr>
                <tr >
                    <td style="width:135pt">
                        <p class="s10" style="padding-left: 62pt;text-indent: 0pt;text-align: left;">TOTAL</p>
                    </td>
                    <td style="width:120pt">
                        <p class="s10" style="padding-right: 5pt;text-indent: 0pt;text-align: right;">......</p>
                    </td>
                </tr>
            </table>
        </div>


          <div style="display: flex; justify-content: space-between;margin-top: 10px; margin-left: 15%; text-align: center; ">
              <div>
                  <p style="text-indent: 0pt;text-align: left;">Le paiement de la somme ci-dessus indiquée soit <b>{{data.amende[0].montant}} F</b></p>
                  <p style="text-indent: 0pt;text-align: left;">devra intervenir avant le <b>............</b></p>

                  <p style="text-indent: 0pt;text-align: left;">à la caisse du préposé du trésor.</p>


                  <div>
                      <p style="text-indent: 0pt;text-align: left;">Payé le . . . . . .</p>
                      <p style="text-indent: 0pt;text-align: left;">selon quitance n° . . . . . .</p>
                  </div>

              </div>

          </div>

          <div style="padding-right: 20px;padding-left: 20px;" class="mfooter">
            <p style="text-align: right;">Pour extrait délivré le {{getFormattedDate(data.creation)}}</p>


            <div style="display: flex; justify-content: space-between;">
                <p>OBSERVATIONS</p>
                <p>Le Procureur de la République</p>
                <p >TRESOR</p>
            </div>
          </div>


            </div>

          </div>

        </div>
      </div>
    </section>
  </div>

</body>
</html>
