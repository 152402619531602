import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { MyCONST } from '../../CONST/myConst';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {FrapperService} from "../frapper/frapper.service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import { PenalCourrierService } from '../penal.courrier/penal.courrier.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // constructor() {}
  token:String;
  API_URL = MyCONST.API_URL;

  constructor(private http: HttpClient,private _route: Router,  private frapper: FrapperService, private penalService :PenalCourrierService){}

ROLES_PATH=[

  {"role":"AGENT DU COURRIER","primay_route":"penal/courrier"},
  {"role":"AGENT DU COURRIER CIVIL","primay_route":"penal/courrier"},
  {"role":"AGENT DU COURRIER FAMILIAL","primay_route":"penal/courrier"},
  {"role":"AGENT DU COURRIER SOCIAL","primay_route":"penal/courrier"},
  {"role":"AGENT DU COURRIER PRESIDENT","primay_route":"penal/courrier"},
  {"role":"AGENT DU COURRIER GREF","primay_route":"penal/courrier"},


  {"role":"AGENT NATURALISATION","primay_route":"CIVIL/naturalisation"},
  {"role":"AGENT EXECUTION PEINES","primay_route":"CIVIL/exec_peines"},


  {"role":"GREFFIER","primay_route":"GREFFIER"},
  {"role":"GREFFIER EN CHEF","primay_route":"GREFFIER"},

  {"role":"SEC PROCUREUR","primay_route":"PENAL/secretaire"},
  {"role":"SUB PROCUREUR","primay_route":"PENAL/substitut"},
  {"role":"PROCUREUR","primay_route":"PENAL/"},

  {"role":"JUGE D'INSTRUCTION","primay_route":"PENAL/juge-instruction"},
  {"role":"JUGE","primay_route":"PENAL/juge"},
  {"role":"PRESIDENT DU TRIBUNAL","primay_route":"PENAL/president-tribunal"},

  {"role":"AGENT ENROLEMENT","primay_route":"GREFFIER/ag-enrolement"},
  {"role":"AGENT ENROLEMENT PENAL","primay_route":"GREFFIER/ag-enrolement"},
  {"role":"AGENT RDV","primay_route":"GREFFIER/dashboard-rv"},


  {"role":"PUBLIC","primay_route":"PUBLIC"}
]


  async getRolePath(email:string){
    let user_role:any  = await this.getRole(email);
    this.penalService.profiles_user = user_role.message;
    let result = this.extractRolePath(user_role.message)
    return result
  }
  async getRole(email:string) : Promise<Observable<any>>{
  let user_role  = await this.frapper.GetUserRoles(email).toPromise();
   // console.log('user_role ===> ',result)
    return user_role
  }
  extractRolePath(user_roles) {
    const extractedRoles = [];
    const rolesPath = this.ROLES_PATH

    // Parcourir chaque élément de ROLES_PATH
    for (const roleInfo of rolesPath) {
      const role = roleInfo.role;

      // Vérifier si le rôle se trouve dans le message
      if (user_roles.includes(role)) {
        extractedRoles.push(roleInfo);
      }
    }

    return extractedRoles;
  }




  generateBearerToken(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const tokenLength = length || 32;
  let token = '';

  for (let i = 0; i < tokenLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
  }

  // return 'Bearer ' + token;
  return  token;

}



  logOut(){
  localStorage.removeItem('profil');
  localStorage.removeItem('access_key');
  localStorage.removeItem('me');

  this._route.navigate(['/']);
}

// Example usage




// login(pseud: string, pass: string) {

//   let userFound:User ;
//   this.users.forEach(user => {
//       if (user.email === pseud && user.password === pass) {
//           this.token = this.generateBearerToken(32);
//           console.log('User found and token generated: ' + this.token);
//           userFound = user;
//           localStorage.setItem('token', JSON.stringify(this.token));
//           localStorage.setItem('profil', user.profil.toString());
//       }
//   });

//   if (!userFound) {
//     userFound = null;

//       console.log('User not found');
//       // Gérer le cas où l'utilisateur n'est pas trouvé
//   }
//   return userFound;
// }

login2(pseud: String, pass: String) {
  //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  return this.http.post(this.API_URL + 'login',
    {usr: pseud, pwd: pass},{headers: headers}
  ).pipe(
    tap((response: any) => {
      console.log({'daaatttaa': response});


    }),
  );
}


 getToken(): any {
    const tok = localStorage.getItem('token');
    if (tok != null) {
      this.token = JSON.parse(tok);

    }
    // console.log('thththt',this.token);

    return this.token;
  }
}
